import React from 'react'
import PropTypes from 'prop-types'
import CardItem from '../CardItem'
import CardItemContent from '../CardItemContent'
import {
  DeviceConfigurationResult,
  ConstraintsDataTypes,
  ConstraintsDataTypesKeys
} from '../../static/consts'
import StatefulIcon from '../StatefulIcon'
import EmptyLeftItem from '../EmptyLeftItem'

const Web = ({
  printPlanHyperLink,
  i18n,
  isBasicPrinter,
  getSecurityCheckStringsForFlexProtectionStatus,
  monitorSecuritySettingsStrings,
  wifiEncryptionStrings
}) => {
  const joinHpPlusString = i18n.t('shared.joinHpPlus')
  const hereString = i18n.t('shared.printPlanHyperLink')
  const legacyPrinterDescriptionString = i18n.t(
    'shared.legacyPrinterDescriptionString'
  )
  const input = isBasicPrinter ? (
    legacyPrinterDescriptionString
  ) : (
    <span>
      {joinHpPlusString}
      <a href={printPlanHyperLink}>{hereString}</a>
    </span>
  )

  return (
    <ul className="ss-non-hpplus">
      <CardItem
        leftItem={<EmptyLeftItem />}
        contentItem={
          <CardItemContent
            title={monitorSecuritySettingsStrings.titleSecurityDisabled}
            content={input}
          />
        }
      />
      {ConstraintsDataTypes.map((securityCheckName) => {
        const { title, description } =
          getSecurityCheckStringsForFlexProtectionStatus(
            securityCheckName,
            wifiEncryptionStrings,
            DeviceConfigurationResult.compliant
          )

        if (securityCheckName === ConstraintsDataTypesKeys.network) return <></>

        return (
          <CardItem
            customCss={{
              padding: '20px 0 20px 0',
              margin: '0',
              width: 'auto'
            }}
            key={title}
            contentItem={
              <CardItemContent
                title={title}
                isSubcontent
                customCss={{ textAlign: 'left' }}
                content={<>{description}</>}
              />
            }
            leftItem={
              <div className="list-item__left-section">
                <StatefulIcon value />
              </div>
            }
          />
        )
      })}
    </ul>
  )
}

export default Web

Web.propTypes = {
  printPlanHyperLink: PropTypes.string.isRequired,
  i18n: PropTypes.object.isRequired,
  monitorSecuritySettingsStrings: PropTypes.object.isRequired,
  wifiEncryptionStrings: PropTypes.object.isRequired,
  isBasicPrinter: PropTypes.bool.isRequired,
  getSecurityCheckStringsForFlexProtectionStatus: PropTypes.func.isRequired
}
