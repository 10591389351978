import { DeviceConfigurationsId } from '../../static/consts'
import passwordProtectionStatusStrings from './passwordProtectionStatusStrings'
import protectionStatusStrings from './protectionStatusStrings'
import safetyStatusStrings from './safetyStatusStrings'

const _getDeviceConfigurationsStrings = (i18n, configurationAssessment) => {
  const configurationId = configurationAssessment.id
  let strings = {}

  const configAdminPasswordSet =
    DeviceConfigurationsId.CONFIG_ADMIN_PASSWORD_SET
  const configAutoFWUpdate = DeviceConfigurationsId.CONFIG_AUTO_FW_UPDATE
  const configWifiNetwork = DeviceConfigurationsId.CONFIG_WIFI_NETWORK

  if (configurationAssessment) {
    switch (configurationId) {
      case configAdminPasswordSet:
        strings = {
          ...strings,
          ...passwordProtectionStatusStrings(i18n, configAdminPasswordSet)
        }
        break
      case configAutoFWUpdate:
        strings = {
          ...strings,
          ...protectionStatusStrings(i18n, configAutoFWUpdate)
        }
        break
      case configWifiNetwork:
        strings = {
          ...strings,
          ...safetyStatusStrings(i18n, 'configWifiNetwork') // Response Id != from string file
        }
        break
      default:
        break
    }
  }

  return { [configurationId]: strings }
}

export const configurationStrings = (i18n, deviceConfigurations) => {
  const configurations = Object.values(deviceConfigurations)
  let deviceConfigurationStrings = {}

  const _confName = (conf) => {
    switch (conf.id) {
      case DeviceConfigurationsId.CONFIG_WIFI_NETWORK:
        return 'configWifiNetworkStrings'
      default:
        return `${conf.id}Strings`
    }
  }
  configurations.forEach((conf) => {
    const deviceConf = Object.values(
      _getDeviceConfigurationsStrings(i18n, conf)
    )[0]
    deviceConfigurationStrings = {
      ...deviceConfigurationStrings,
      [_confName(conf)]: deviceConf
    }
  })
  return deviceConfigurationStrings
}
