import json from '../assets/locale/index'

function stringParser(string, keys) {
  const templateMatcher = /{{\s?([^{}\s]*)\s?}}/g
  return string.replace(templateMatcher, (substring, idx) => keys[idx])
}

class I18n {
  lang: string
  constructor(lang) {
    this.lang = lang
  }

  t(id, keys = null) {
    try {
      const rawString = id.split('.').reduce((o, i) => o[i], json[this.lang])
      return stringParser(rawString, keys)
    } catch (err) {
      return id
    }
  }
}

function fallbackLanguage(locale) {
  const languages = {
    en: 'en',
    en_CA: 'en_US',
    en_GB: 'en_US',
    en_IE: 'en_US',
    en_AU: 'en_US',
    en_NZ: 'en_US',
    en_MT: 'en_US',
    en_PR: 'en_US',
    en_IS: 'en_US',
    en_BZ: 'en_US',
    en_CR: 'en_US',
    en_VI: 'en_US',
    en_SX: 'en_US',
    en_BS: 'en_US',
    en_JM: 'en_US',
    en_KY: 'en_US',
    en_TC: 'en_US',
    en_AG: 'en_US',
    en_LC: 'en_US',
    en_VC: 'en_US',
    en_GD: 'en_US',
    en_BB: 'en_US',
    en_TT: 'en_US',
    en_MS: 'en_US',
    en_AI: 'en_US',
    en_KN: 'en_US',
    en_RS: 'en_US',
    en_ZA: 'en_US',
    en_NG: 'en_US',
    en_AL: 'en_US',
    en_BA: 'en_US',
    en_ME: 'en_US',
    en_AZ: 'en_US',
    en_BW: 'en_US',
    en_CM: 'en_US',
    en_ET: 'en_US',
    en_GE: 'en_US',
    en_GH: 'en_US',
    en_KE: 'en_US',
    en_MK: 'en_US',
    en_MU: 'en_US',
    en_NA: 'en_US',
    en_RW: 'en_US',
    en_TZ: 'en_US',
    en_UG: 'en_US',
    en_ZM: 'en_US',
    en_IN: 'en_US',
    en_BD: 'en_US',
    en_LK: 'en_US',
    en_SG: 'en_US',
    en_MY: 'en_US',
    en_TH: 'en_US',
    en_PH: 'en_US',
    en_ID: 'en_US',
    en_VN: 'en_US',
    en_PK: 'en_US',
    en_MM: 'en_US',
    en_LA: 'en_US',
    en_KH: 'en_US',
    en_BN: 'en_US',
    en_TL: 'en_US',
    en_NP: 'en_US',
    en_MV: 'en_US',
    en_MN: 'en_US',
    en_AF: 'en_US',
    en_BT: 'en_US',
    en_CY: 'en_US',
    en_IL: 'en_US',
    en_VG: 'en_US',
    en_JP: 'en_US',
    en_AE: 'en_US',
    en_SA: 'en_US',
    en_TN: 'en_US',
    en_MA: 'en_US',
    en_DZ: 'en_US',
    en_BH: 'en_US',
    en_EG: 'en_US',
    en_JO: 'en_US',
    en_KW: 'en_US',
    en_LB: 'en_US',
    en_LY: 'en_US',
    en_OM: 'en_US',
    en_QA: 'en_US',
    en_TW: 'en_US',
    en_HK: 'en_US',
    en_AW: 'en_US',
    es_MX: 'es_ES',
    es_PY: 'es_ES',
    es_UY: 'es_ES',
    es_BO: 'es_ES',
    es_VE: 'es_ES',
    es_EC: 'es_ES',
    es_CL: 'es_ES',
    es_PE: 'es_ES',
    es_AR: 'es_ES',
    es_CO: 'es_ES',
    es_BZ: 'es_ES',
    es_SV: 'es_ES',
    es_GT: 'es_ES',
    es_HN: 'es_ES',
    es_NI: 'es_ES',
    es_PA: 'es_ES',
    es_DO: 'es_ES',
    es_CU: 'es_ES',
    es_PR: 'es_ES',
    fr_CH: 'fr_FR',
    fr_BE: 'fr_FR',
    fr_LU: 'fr_FR',
    fr_HT: 'fr_FR',
    fr_GP: 'fr_FR',
    fr_MQ: 'fr_FR',
    fr_MF: 'fr_FR',
    fr_BL: 'fr_FR',
    fr_LC: 'fr_FR',
    fr_BJ: 'fr_FR',
    fr_CI: 'fr_FR',
    fr_GF: 'fr_FR',
    fr_MG: 'fr_FR',
    fr_YT: 'fr_FR',
    fr_NC: 'fr_FR',
    fr_RE: 'fr_FR',
    fr_SN: 'fr_FR',
    fr_PF: 'fr_FR',
    nl_CW: 'nl_BE',
    nl_BQ: 'nl_BE',
    nl_NL: 'nl_NL',
    de_AT: 'de_DE',
    de_CH: 'de_DE',
    de_LI: 'de_DE',
    de_LU: 'de_DE',
    ru_KZ: 'ru_RU',
    ru_BY: 'ru_RU',
    ru_UA: 'ru_RU',
    ru_UZ: 'ru_RU',
    pt_AO: 'pt_PT',
    pt_MZ: 'pt_PT',
    sv_FI: 'sv_SE',
    it_CH: 'it_IT',
    el_CY: 'el_GR',
    ro_MD: 'ro_RO',
    zh_HK: 'zh_TW'
  }
  return languages[locale] || 'en'
}

function createI18n(lang = 'en') {
  if (json[lang] == null) {
    return new I18n(fallbackLanguage(lang))
  }

  return new I18n(lang)
}

export default createI18n
