import {
  SHOW_SECURITY_MODAL,
  DISMISS_SECURITY_MODAL,
  SET_MONITORING_IN_PROGRESS,
  FINISH_MONITORING_IN_PROGRESS
} from '../actions/monitorSecuritySettings'

export const requestSecurityMonitoringReducer = (state, action) => {
  switch (action.type) {
    case SET_MONITORING_IN_PROGRESS:
      return { ...state, isMonitoringInProgress: true }
    case FINISH_MONITORING_IN_PROGRESS:
      return { ...state, isMonitoringInProgress: false }
    case SHOW_SECURITY_MODAL:
      return { ...state, isShowingModal: true }
    case DISMISS_SECURITY_MODAL:
      return { ...state, isShowingModal: false }
    default:
      return state
  }
}
