import React from 'react'
import PropTypes from 'prop-types'
import List from '@veneer/core/dist/scripts/list'
import { IconInfo } from '@veneer/core/dist/scripts/icons'
import Tooltip from '@veneer/core/dist/scripts/tooltip'
import { sprintf } from 'sprintf-js'
import {
  ConstraintsDataTypes,
  ConstraintsDataTypesKeys,
  Sizes,
  Colors,
  DeviceConfigurationResult
} from '../../static/consts'
import CardItem from '../CardItem'
import CardItemContent from '../CardItemContent'
import { withDropShadow } from '../../hocs/withDropShadow'

const Mobile = ({
  i18n,
  printPlanHyperLink,
  isBasicPrinter,
  getSecurityCheckStringsForFlexProtectionStatus,
  monitorSecuritySettingsStrings,
  wifiEncryptionStrings
}) => {
  const joinHpPlusString = i18n.t('shared.joinHpPlus')
  const hereString = i18n.t('shared.printPlanHyperLink')
  const legacyPrinterDescriptionString = i18n.t(
    'shared.legacyPrinterDescriptionString'
  )

  const input = isBasicPrinter
    ? legacyPrinterDescriptionString
    : `<span>${sprintf(
        joinHpPlusString,
        `<a href=${printPlanHyperLink}>${hereString}</a>`
      )}</span>`

  return withDropShadow(
    List,
    true
  )({
    children: (
      <>
        <CardItem
          key={monitorSecuritySettingsStrings.titleSecurityDisabled}
          contentItem={
            <CardItemContent
              title={monitorSecuritySettingsStrings.titleSecurityDisabled}
              isSubcontent
              content={input}
            />
          }
        />
        {ConstraintsDataTypes.map((securityCheckName) => {
          const { title, description } =
            getSecurityCheckStringsForFlexProtectionStatus(
              securityCheckName,
              wifiEncryptionStrings,
              DeviceConfigurationResult.compliant
            )
          const isNetworkType =
            securityCheckName === ConstraintsDataTypesKeys.network
          if (isNetworkType) {
            return <></>
          }
          return (
            <CardItem
              key={title}
              contentItem={
                <CardItemContent title={title} isSubcontent content={<></>} />
              }
              rightItem={
                <Tooltip
                  arrow
                  content={description}
                  placement="bottom-end"
                  data-testid="ss-tooltip"
                >
                  <div style={{ padding: 0 }}>
                    <IconInfo
                      size={Sizes.MOBILE_WARNING_SIZE}
                      color={Colors.MOBILE_INFO_BLUE}
                      data-testid="ss-icon-info"
                    />
                  </div>
                </Tooltip>
              }
            />
          )
        })}
      </>
    )
  })
}

export default Mobile

Mobile.propTypes = {
  printPlanHyperLink: PropTypes.string.isRequired,
  i18n: PropTypes.object.isRequired,
  monitorSecuritySettingsStrings: PropTypes.object.isRequired,
  wifiEncryptionStrings: PropTypes.object.isRequired,
  isBasicPrinter: PropTypes.bool.isRequired,
  getSecurityCheckStringsForFlexProtectionStatus: PropTypes.func.isRequired
}
