import { useState, useEffect, useContext } from 'react'
import utf8 from 'utf8'
import { useToast } from '@veneer/core'
import AssessmentContext from '../contexts/AssessmentContext'
import {
  ToastIDs,
  ToastTypes,
  DeviceConfigurationsValue,
  AnalyticsClickEvents
} from '../static/consts'
import { getToastErrorStrings } from '../strings/Errors/index'
import AuthContext from '../contexts/AuthContext'

import { compliancyForResult } from '../utils/shared'
import { registerAnalyticsEvents } from '../utils/analytics'

const extractPwdComplexity = (configAdminPasswordSet_) => {
  const configControls = configAdminPasswordSet_.configcontrols.find(
    (c) => c.id === 'controlAdminPasswordSet_PasswordText'
  )
  if (
    configControls &&
    configControls.constraintsData &&
    configControls.constraintsData.passwordcomplexity
  ) {
    const { enabled, pattern } =
      configControls.constraintsData.passwordcomplexity
    return {
      enabled,
      pattern
    }
  }
  return {}
}

export const useSetAdminPassword = ({
  configAdminPasswordSet,
  useAssessmentStringsHook
}) => {
  const frontPattern = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{8,}$/
  const { printer, requestAssessment, setHasCallRemediation } =
    useContext(AssessmentContext)
  const { configAdminPasswordSetStrings } = useAssessmentStringsHook
  const [isLoading, setIsLoading] = useState(false)
  const [password, setPassword] = useState('')
  const [confirmationPassword, setConfirmationPassword] = useState('')

  const isPwdUnknownValue =
    configAdminPasswordSet.value === DeviceConfigurationsValue.unknownValue

  const [isCompliant, setIsCompliant] = useState(
    compliancyForResult(configAdminPasswordSet)
  )

  const { client, i18n } = useContext(AuthContext)

  const [
    isPasswordCompliantWithRequirements,
    setIsPasswordCompliantWithRequirements
  ] = useState(true)
  const [isConfirmPwdCompliant, setIsConfirmPwdCompliant] = useState(true)

  const onInputPassword = (e) => {
    const passwordValue = e
    const encodedPassword = utf8.encode(passwordValue)
    setPassword(encodedPassword)
  }

  const onInputConfirmationPassword = (e) => {
    const confirmationPasswordValue = e
    const encodedConfirmationPassword = utf8.encode(confirmationPasswordValue)
    setConfirmationPassword(encodedConfirmationPassword)
  }

  const [
    passwordMeetingRequirementsMessage,
    setPasswordMeetingRequirementsMessage
  ] = useState('')

  const [
    confirmationPasswordMatchMessage,
    setConfirmationPasswordMatchMessage
  ] = useState('')

  const verifyConfirmationPwdInput = () => {
    const isEqual = password === confirmationPassword
    if (isEqual) {
      setConfirmationPasswordMatchMessage(undefined)
    } else {
      setConfirmationPasswordMatchMessage(
        configAdminPasswordSetStrings.differentPasswordsError
      )
      setIsPasswordCompliantWithRequirements(false)
    }
    setIsConfirmPwdCompliant(isEqual)
    return isEqual
  }

  const verifyPasswordInput = () => {
    const { enabled, pattern } = extractPwdComplexity(configAdminPasswordSet)
    if (enabled) {
      const newPattern =
        pattern && pattern !== '' ? new RegExp(pattern) : frontPattern
      if (newPattern.test(password)) {
        setIsPasswordCompliantWithRequirements(true)
        setPasswordMeetingRequirementsMessage(undefined)
        return true
      }
      setIsPasswordCompliantWithRequirements(false)
      setPasswordMeetingRequirementsMessage(
        configAdminPasswordSetStrings.requirementError
      )
    } else if (frontPattern.test(password)) {
      setIsPasswordCompliantWithRequirements(true)
      setPasswordMeetingRequirementsMessage(undefined)
      return true
    } else {
      setIsPasswordCompliantWithRequirements(false)
      setPasswordMeetingRequirementsMessage(
        configAdminPasswordSetStrings.requirementError
      )
    }
    return false
  }

  const { addToast } = useToast()

  const toastStrings = (err) => {
    const setAdminPasswordToastString = {
      text: configAdminPasswordSetStrings.toast
    }
    if (err) return getToastErrorStrings(err.data, i18n)
    return setAdminPasswordToastString
  }

  const setUpToast = (err) => {
    const toast = {
      id:
        (err
          ? ToastIDs.setAutoFWUpdate.NEGATIVE
          : ToastIDs.setAutoFWUpdate.POSITIVE) + printer.deviceId,
      type: err ? ToastTypes.NEGATIVE : ToastTypes.POSITIVE,
      ...toastStrings(err)
    }
    return {
      id: toast.id,
      subtitle: toast.subtitle,
      type: toast.type,
      text: toast.text
    }
  }

  const cleanPasswordsField = () => {
    setPassword('')
    setConfirmationPassword('')
  }

  async function onSetAdminPassword(password_) {
    let err
    setHasCallRemediation(true)
    setIsLoading(true)
    try {
      await client.setAdminPwdConfig(printer.deviceId, password_)
      cleanPasswordsField()
    } catch (error) {
      err = error
      setIsLoading(false)
    }
    addToast(setUpToast(err))
    requestAssessment()
  }

  const verifyPassword = () => {
    if (verifyPasswordInput() & verifyConfirmationPwdInput()) {
      onSetAdminPassword(password)
      registerAnalyticsEvents(AnalyticsClickEvents.SETPASSWORD)
    }
  }

  useEffect(() => {
    const isPasswordCompliant = String(configAdminPasswordSet.value) === 'true'
    setIsCompliant(isPasswordCompliant)
    setIsLoading(false)
  }, [configAdminPasswordSet])

  const clearPwdMsg = () => {
    setPasswordMeetingRequirementsMessage(undefined)
  }

  const clearPwdConfirmMsg = () => {
    setConfirmationPasswordMatchMessage(undefined)
  }

  return {
    clearPwdMsg,
    clearPwdConfirmMsg,
    verifyPasswordInput,
    verifyConfirmationPwdInput,
    isConfirmPwdCompliant,
    confirmationPasswordMatchMessage,
    passwordMeetingRequirementsMessage,
    configAdminPasswordSetStrings,
    password,
    isPasswordCompliantWithRequirements,
    confirmationPassword,
    onInputPassword,
    onInputConfirmationPassword,
    verifyPassword,
    isLoading,
    isCompliant,
    printerID: printer.deviceId,
    isPwdUnknownValue
  }
}
