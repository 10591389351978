import React, { useRef, useEffect } from 'react'
import PropTypes from 'prop-types'

export const withDropShadow = (Component, isEnabled) => {
  const WithComponent = ({ children, props }: any) => {
    const componentRef: any = useRef()

    useEffect(() => {
      if (!componentRef.current) {
        return
      }

      if (isEnabled) {
        componentRef.current.style.boxShadow = `rgba(33, 33, 33, 0.1) 0px 4px 12px`
        componentRef.current.style.borderRadius = '12px'
      } else {
        componentRef.current.style.boxShadow = ''
        componentRef.current.style.borderRadius = ''
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isEnabled])
    return (
      <Component ref={componentRef} {...props}>
        {children}
      </Component>
    )
  }

  WithComponent.propTypes = {
    children: PropTypes.object.isRequired,
    props: PropTypes.array.isRequired
  }

  return WithComponent
}
