import React from 'react'
import Password from '@veneer/core/dist/scripts/password'
import Button from '@veneer/core/dist/scripts/button'
// import PropTypes from 'prop-types';
import CardItem from '../CardItem'
import WarningMarker from '../WarningMarker'
import CardItemContent from '../CardItemContent'

interface PasswordFormProps {
  useSetAdminPassword: any
  isMobileSize?: boolean
}
const PasswordForm = ({
  useSetAdminPassword,
  isMobileSize
}: PasswordFormProps) => {
  const defaultPadding = {
    paddingTop: '10px'
  }

  const {
    isPwdUnknownValue,
    isInProgress,
    confirmationPasswordMatchMessage,
    passwordMeetingRequirementsMessage,
    configAdminPasswordSetStrings,
    password,
    isPasswordCompliantWithRequirements,
    confirmationPassword,
    onInputPassword,
    onInputConfirmationPassword,
    verifyPassword,
    isLoading,
    isCompliant,
    printerID,
    clearPwdMsg,
    clearPwdConfirmMsg,
    verifyPasswordInput,
    verifyConfirmationPwdInput,
    isConfirmPwdCompliant
  } = useSetAdminPassword

  const verifyInputs = () => {
    verifyPasswordInput()
    verifyConfirmationPwdInput()
  }

  const contentItemCard = (
    <CardItemContent
      customCss={{
        ...defaultPadding,
        ...(isMobileSize ? { whiteSpace: 'normal' } : {})
      }}
      isSubcontent
      title={configAdminPasswordSetStrings.title2}
      content={<>{configAdminPasswordSetStrings.recommendation}</>}
    >
      <Password
        value={password}
        onChange={onInputPassword}
        onFocus={() => {
          clearPwdMsg()
          verifyConfirmationPwdInput()
        }}
        maxLength={16}
        onBlur={verifyInputs}
        error={!isPasswordCompliantWithRequirements}
        disabled={isCompliant}
        helperText={passwordMeetingRequirementsMessage}
        css={{ ...defaultPadding, maxWidth: '380px' }}
        label={configAdminPasswordSetStrings.passwordField}
        name="password"
        id={`password${printerID}`}
        data-testid="ss-password"
      />
      <Password
        value={confirmationPassword}
        onFocus={() => {
          clearPwdConfirmMsg()
          verifyPasswordInput()
        }}
        onBlur={verifyInputs}
        maxLength={16}
        onChange={onInputConfirmationPassword}
        error={!isConfirmPwdCompliant}
        helperText={confirmationPasswordMatchMessage}
        disabled={isCompliant}
        css={{
          ...defaultPadding,
          maxWidth: '380px',
          paddingBottom: '20px'
        }}
        label={configAdminPasswordSetStrings.confirmationPasswordField}
        name="password-confirmation"
        id={`password-confirmation${printerID}`}
        data-testid="ss-password-confirmation"
      />
      <div
        className={isMobileSize ? '' : 'line-break'}
        data-testid="ss-printer-password-hint"
      >
        {configAdminPasswordSetStrings.recommendation2}
      </div>
      <Button
        disabled={isCompliant}
        css={{ marginTop: '20px' }}
        onClick={verifyPassword}
        loading={isLoading}
        data-testid="ss-setPassword-button"
      >
        {configAdminPasswordSetStrings.title2}
      </Button>
    </CardItemContent>
  )

  return (
    <CardItem
      leftItem={
        isMobileSize ? undefined : (
          <WarningMarker
            isSecured={isCompliant || isInProgress || isPwdUnknownValue}
          />
        )
      }
      contentItem={
        isMobileSize ? (
          contentItemCard
        ) : (
          <div style={{ marginLeft: '38px' }}>{contentItemCard}</div>
        )
      }
    />
  )
}

export default PasswordForm

// PasswordForm.propTypes = {
//   useSetAdminPassword: PropTypes.object.isRequired,
//   isMobileSize: PropTypes.bool,
// };

// PasswordForm.defaultProps = {
//   isMobileSize: false,
// };
