import React from 'react'
import Toggle from '@veneer/core/dist/scripts/toggle'
import Button from '@veneer/core/dist/scripts/button'
import Modal from '@veneer/core/dist/scripts/modal'
import ProgressIndicator from '@veneer/core/dist/scripts/progress_indicator'
import List from '@veneer/core/dist/scripts/list'
// import { LogoHpPlus } from '@veneer/core/dist/scripts/logos'
import PropTypes from 'prop-types'
import CardItem from '../CardItem'
import CardItemContent from '../CardItemContent'
import { AppColors, SecurityAssessmentStatus } from '../../static/consts'
import { withDropShadow } from '../../hocs/withDropShadow'

const Mobile = ({
  active,
  strings,
  isEnabled,
  isAssessmentInProgress,
  state,
  onToggleMonitoring,
  showSecurityModal,
  dismissSecurityModal
}) => {
  const warningCssBase = {
    border: `1px solid ${AppColors.warning}`,
    borderRadius: '12px'
  }

  const rightItem = () => {
    if (state.isMonitoringInProgress || isAssessmentInProgress) {
      return <ProgressIndicator data-testid="ss-progressIndicator" />
    }
    if (active === SecurityAssessmentStatus.trueValue) {
      return (
        <Toggle
          on={isEnabled}
          disabled={state.isMonitoringInProgress}
          onChange={showSecurityModal}
          id="toggle"
          data-testid="ss-monitor-toggle"
        />
      )
    }
    return <></>
  }

  const shadowedList = withDropShadow(List, true)
  return (
    <>
      <div>
        <Modal
          id="ss-modal"
          align="center"
          onClose={dismissSecurityModal}
          show={state.isShowingModal}
          title={strings.modalTitle}
          data-testid="ss-monitorModal"
          footer={
            <div className="vn-button-group--responsive">
              <Button
                onClick={isEnabled ? dismissSecurityModal : onToggleMonitoring}
                data-testid="ss-monitorModal-security-button"
              >
                {strings.buttonPrimaryTitle}
              </Button>
              <Button
                appearance="secondary"
                onClick={isEnabled ? onToggleMonitoring : dismissSecurityModal}
                data-testid="ss-monitorModal-removeSecurity-button"
              >
                {strings.buttonSecondaryTitle}
              </Button>
            </div>
          }
        >
          <p>{strings.modalDescription}</p>
        </Modal>
      </div>
      <div style={isEnabled ? null : warningCssBase}>
        {shadowedList({
          children: (
            <>
              <CardItem
                customCss={{
                  paddingLeft: '20px',
                  paddingTop: '10px',
                  paddingBottom: '0'
                }}
                leftItem={<div style={{ height: '36px' }} />}
                contentItem={
                  <CardItemContent isSubcontent title={strings.title} />
                }
              />
              <CardItem
                contentItem={
                  <CardItemContent
                    isSubcontent
                    content={<>{strings.description}</>}
                  />
                }
                rightItem={rightItem()}
              />
            </>
          )
        })}
      </div>
    </>
  )
}

export default Mobile

Mobile.propTypes = {
  active: PropTypes.string.isRequired,
  strings: PropTypes.object.isRequired,
  isEnabled: PropTypes.bool.isRequired,
  isAssessmentInProgress: PropTypes.bool.isRequired,
  state: PropTypes.object.isRequired,
  onToggleMonitoring: PropTypes.func.isRequired,
  showSecurityModal: PropTypes.func.isRequired,
  dismissSecurityModal: PropTypes.func.isRequired
}
