import React from 'react'
import Accordion from '@veneer/core/dist/scripts/accordion'
import List from '@veneer/core/dist/scripts/list'
import { IconInfo } from '@veneer/core/dist/scripts/icons'
import Tooltip from '@veneer/core/dist/scripts/tooltip'
import StatefulIcon from '../StatefulIcon'
import CardItem from '../CardItem'
import CardItemContent from '../CardItemContent'
import {
  ConstraintsDataTypes,
  ConstraintsDataTypesKeys,
  AppColors,
  Sizes,
  Colors
} from '../../static/consts'
// import '../../../css/SmartSecurityStyles.css';
import { withDropShadow } from '../../hocs/withDropShadow'

const Mobile = ({
  strings,
  getSecurityCheckStringsForProtectionStatus,
  active,
  onAccordionClick,
  isUnknown,
  isCompliant,
  isComplianceOff
}) => {
  const warningCssBase = {
    border: `1px solid ${AppColors.warning}`,
    borderRadius: '12px'
  }

  const accordionExpandedContent = () => {
    if (active && !isUnknown) {
      return (
        <List>
          {ConstraintsDataTypes.map((securityCheckName) => {
            const { title, description } =
              getSecurityCheckStringsForProtectionStatus(
                securityCheckName,
                strings,
                isCompliant
              )

            const isNetworkSecurityCheck =
              securityCheckName === ConstraintsDataTypesKeys.network

            if (isComplianceOff && isNetworkSecurityCheck) return <></>

            return (
              <CardItem
                leftItem={
                  isNetworkSecurityCheck &&
                  !isCompliant && (
                    <StatefulIcon
                      value={false}
                      filled
                      size={Sizes.MOBILE_WARNING_SIZE}
                    />
                  )
                }
                rightItem={
                  isNetworkSecurityCheck && !isCompliant ? (
                    <></>
                  ) : (
                    <Tooltip
                      arrow
                      content={description}
                      placement="bottom-end"
                      data-testid="ss-tooltip"
                    >
                      <div style={{ padding: 0 }}>
                        <IconInfo
                          size={Sizes.MOBILE_WARNING_SIZE}
                          color={Colors.MOBILE_INFO_BLUE}
                          data-testid="ss-icon-info"
                        />
                      </div>
                    </Tooltip>
                  )
                }
                key={title}
                contentItem={
                  <CardItemContent
                    title={title}
                    isSubcontent
                    content={
                      <>
                        {isNetworkSecurityCheck && !isCompliant
                          ? description
                          : ''}
                      </>
                    }
                  />
                }
              />
            )
          })}
        </List>
      )
    }
    return <></>
  }

  const unknownCard = (
    <CardItem
      leftItem={<StatefulIcon value={false} />}
      contentItem={
        <CardItemContent
          title={strings.title}
          content={<>{strings.description}</>}
        />
      }
      hasShadow
    />
  )

  const accordion = (
    <Accordion
      onExpand={onAccordionClick}
      onCollapse={onAccordionClick}
      data-testid="ss-accordion"
      items={[
        {
          header: {
            startArea: <StatefulIcon value={isCompliant || isComplianceOff} />,
            centralArea: (
              <CardItemContent
                titleStyle={{ marginTop: '12px' }}
                customCss={{ marginBottom: '12px' }}
                title={strings.title}
                content={<>{strings.description}</>}
              />
            )
          },
          expanded: active,
          content: <>{accordionExpandedContent()}</>,
          id: 'accordionProtectionStatusMobileItemID'
        }
      ]}
      id="accordionProtectionStatusMobileID"
    />
  )

  const content = () => {
    return withDropShadow(
      'div',
      true
    )({
      props: {
        className: 'ss-accordion-mobile',
        style: isCompliant || isComplianceOff ? {} : warningCssBase
      },
      children: accordion
    })
  }

  return isUnknown ? unknownCard : content()
}

export default Mobile
