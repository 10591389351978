import { ShellProps } from '../../src/types/shell'

declare global {
  interface Window {
    Shell: ShellProps
  }
}

export const navigateToPortal = () => {
  if (window.Portal) {
    window.Portal.deeplinks.navigateFunction({
      schema: 'hp-smart-app',
      key: 'help_hp_plus',
      path: 'hpsmartsecurity'
    })
  } else if (window?.Shell?.v1?.navigation) {
    window?.Shell?.v1.navigation.push('/help/hp-plus')
  }
}
