// import '../../../css/SmartSecurityStyles.css';
import React from 'react'
import { sprintf } from 'sprintf-js'
import PropTypes from 'prop-types'
import CardItem from '../CardItem'
import CardItemContent from '../CardItemContent'
import PrinterIcon from '../PrinterIcon'
import BadgeIcon from '../BadgeIcon'

const Mobile = ({ isActive, isUnknown, strings, name }) => {
  return (
    <CardItem
      customCss={{ backgroundColor: 'transparent' }}
      contentItem={
        <>
          <div
            style={{
              margin: 'auto',
              gridRow: 0,
              width: 'min-content',
              position: 'relative'
            }}
          >
            <PrinterIcon />
            {isUnknown || !isActive ? '' : <BadgeIcon />}
          </div>
          <CardItemContent
            titleStyle={{
              textAlign: 'center',
              width: '100%',
              wordBreak: 'break-word'
            }}
            title={sprintf(strings.title, name)}
          />
        </>
      }
    />
  )
}

export default Mobile
Mobile.propTypes = {
  isActive: PropTypes.bool.isRequired,
  isUnknown: PropTypes.bool.isRequired,
  strings: PropTypes.object.isRequired,
  name: PropTypes.string.isRequired
}
