import React, { useState, useEffect, useContext } from 'react'
import JarvisFullScreenSpinner from './JarvisFullScreenSpinner'
import FailToLoad from './FailToLoad'
import AuthContext from '../contexts/AuthContext'

const LoadingPage = ({
  isLoadingPageDetails,
  onVisibilityChanged,
  timeout,
  onTimeout,
  errorToLoad
}) => {
  const { i18n } = useContext(AuthContext)
  const [hasFailedToLoad, setHasFailedToLoad] = useState(false)
  const [timerId, setTimerId] = useState(-1)

  useEffect(() => {
    if (!errorToLoad) {
      setTimerId((oldValue): any => {
        clearTimeout(oldValue)
        return setTimeout(() => {
          setHasFailedToLoad(true)
        }, timeout)
      })
    }

    return () => {
      clearTimeout(timerId)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errorToLoad])

  return hasFailedToLoad || errorToLoad ? (
    <div className="ss-details">
      <FailToLoad
        message={i18n.t('errors.failToLoad')}
        btnLabel={i18n.t('errors.tryAgain')}
        btnAction={onTimeout}
        btnLoading={isLoadingPageDetails}
      />
    </div>
  ) : (
    <div className="ss-details">
      <JarvisFullScreenSpinner
        spinnerTextArray={[]}
        minimumDuration={timeout}
        delay={1000}
        show={isLoadingPageDetails}
        onVisibilityChanged={onVisibilityChanged}
      />
    </div>
  )
}

export default LoadingPage
