import React from 'react'
import {
  IconCheckCircle,
  IconWarningAlt
} from '@veneer/core/dist/scripts/icons'
import PropTypes from 'prop-types'
import { Sizes } from '../static/consts'

const StatefulIcon = ({ value, filled, size = Sizes.ICON_SIZE }) => {
  const strValue = String(value)
  const color = strValue === 'true' ? 'colorLightGreen6' : 'colorOrange6'
  return strValue === 'true' ? (
    <IconCheckCircle
      size={size}
      color={color}
      filled={filled}
      data-testid="ss-icon-check-circle"
    />
  ) : (
    <IconWarningAlt
      size={size}
      color={color}
      filled={filled}
      data-testid="ss-icon-warning-alt"
    />
  )
}

StatefulIcon.propTypes = {
  value: PropTypes.any.isRequired,
  filled: PropTypes.bool,
  size: PropTypes.number
}

StatefulIcon.defaultProps = {
  filled: false,
  size: Sizes.ICON_SIZE
}

export default StatefulIcon
