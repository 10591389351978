import { sprintf } from 'sprintf-js'
import { HelperLinks } from '../../static/consts'

export function getToastErrorStrings(data, i18n) {
  const nonNullData = data || {}
  const errorCode = nonNullData.errorCode || 'default'
  switch (errorCode) {
    case 'RemediationError':
      return {
        text: i18n.t('errors.remediationErrorToastText'),
        subtitle: i18n.t('errors.remediationErrorToastSubtitle')
      }
    default:
      return {
        text: i18n.t('errors.toastTextDefault'),
        subtitle: i18n.t('errors.toastSubtitleDefault')
      }
  }
}

export function getErrorMessage(data, i18n) {
  const nonNullData = data || {}
  const httpCode = nonNullData.status || 'default'
  switch (Number(httpCode)) {
    // Bad Request  / Unauthorized / Forbidden
    case 400:
    case 401:
    case 403:
      return {
        message: sprintf(
          i18n.t('errors.securityMonitoringLoadFail01'),
          `<a href=${HelperLinks.link2}>${i18n.t(
            'errors.securityMonitoringLoadFailFAQLink'
          )}</a>`
        ),
        showButton: false
      }
    case 500:
      return {
        message: i18n.t('errors.unexpectedIssueError'),
        showButton: false
      }
    case 502:
      return {
        message: i18n.t('errors.noConnection'),
        showButton: true
      }
    case 503:
      return { message: i18n.t('errors.retryAgain'), showButton: true }
    default:
      return { message: i18n.t('errors.noConnection'), showButton: false }
  }
}
