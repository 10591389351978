import React from 'react'
import PropTypes from 'prop-types'
import CardItem from '../CardItem'
import CardItemContent from '../CardItemContent'
import EmptyLeftItem from '../EmptyLeftItem'

const Web = ({ i18n, strings, showAssessment }) => {
  const SecurityNotAvailable = i18n.t(
    'monitorSecuritySettings.descriptionTenantUnknown'
  )

  const input = <span>{SecurityNotAvailable}</span>
  return (
    <ul className="ss-non-hpplus">
      <CardItem
        leftItem={
          <>
            <EmptyLeftItem />
          </>
        }
        contentItem={
          showAssessment ? (
            <CardItemContent
              title={strings.title}
              content={strings.description}
            />
          ) : (
            <CardItemContent title={strings.title} content={input} />
          )
        }
      />
    </ul>
  )
}

export default Web

Web.propTypes = {
  i18n: PropTypes.object.isRequired,
  strings: PropTypes.object.isRequired,
  showAssessment: PropTypes.bool.isRequired
}
