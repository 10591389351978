// import { JarvisWebHttpClient } from '@jarvis/web-http'
import { DeviceCacheApiClient } from '@jarvis/web-stratus-client'

class DeviceClient {
  stack
  baseUrl
  authProvider

  constructor(stack, baseUrl, authProvider) {
    this.stack = stack
    this.baseUrl = baseUrl + '/v1'
    this.authProvider = authProvider
  }

  exportDevicesApi = async () => {
    const client = new DeviceCacheApiClient(this.baseUrl, this.authProvider)
    const response = await client.list()

    if (this.isStatusCodeOk(response.status)) {
      if (response.data) {
        return response?.data
      }
      return []
    }
    throw response.statusText
  }

  exportDeviceDetailsApi = async (deviceId) => {
    const client = new DeviceCacheApiClient(this.baseUrl, this.authProvider)
    const response = await client.show(deviceId)
    const records = response?.data
    return records
  }

  isStatusCodeOk(status) {
    return status > 199 && status < 400
  }
}

export default DeviceClient
