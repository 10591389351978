import { DeviceConfigurationsValue } from '../../static/consts'

const passwordProtectionStatusStrings = (i18n, deviceConfiguration) => {
  return {
    titleGood: i18n.t(`configurations.${deviceConfiguration}.titleGood`),
    descriptionGood: i18n.t(
      `configurations.${deviceConfiguration}.descriptionGood`
    ),
    titleBad: i18n.t(`configurations.${deviceConfiguration}.titleBad`),
    descriptionBad: i18n.t(
      `configurations.${deviceConfiguration}.descriptionBad`
    ),
    titleUnknown: i18n.t(`configurations.${deviceConfiguration}.titleUnknown`),
    descriptionUnknown: i18n.t(
      `configurations.${deviceConfiguration}.descriptionUnknown`
    ),
    title2: i18n.t(`configurations.${deviceConfiguration}.title2`),
    toast: i18n.t(`configurations.${deviceConfiguration}.toast`),
    errorToastText: i18n.t(
      `configurations.${deviceConfiguration}.errorToastText`
    ),
    errorToastSub: i18n.t(
      `configurations.${deviceConfiguration}.errorToastSub`
    ),
    recommendation: i18n.t(
      `configurations.${deviceConfiguration}.recommendation`
    ),
    recommendation2: i18n.t(
      `configurations.${deviceConfiguration}.recommendation2`
    ),
    passwordField: i18n.t(
      `configurations.${deviceConfiguration}.passwordField`
    ),
    confirmationPasswordField: i18n.t(
      `configurations.${deviceConfiguration}.confirmationPasswordField`
    ),
    requirementError: i18n.t(
      `configurations.${deviceConfiguration}.requirementError`
    ),
    differentPasswordsError: i18n.t(
      `configurations.${deviceConfiguration}.differentPasswordsError`
    ),
    success: i18n.t(`configurations.${deviceConfiguration}.success`)
  }
}

export const stringsForState = (strings, configuration) => {
  let formattedStrings = { ...strings }

  switch (configuration.value) {
    case DeviceConfigurationsValue.trueValue:
      formattedStrings = {
        ...formattedStrings,
        title: strings.titleGood,
        description: strings.descriptionGood
      }
      break
    case DeviceConfigurationsValue.falseValue:
      formattedStrings = {
        ...formattedStrings,
        title: strings.titleBad,
        description: strings.descriptionBad
      }
      break
    case DeviceConfigurationsValue.unknownValue:
    default:
      formattedStrings = {
        ...formattedStrings,
        title: strings.titleUnknown,
        description: strings.descriptionUnknown
      }
      break
  }

  return formattedStrings
}

export default passwordProtectionStatusStrings
