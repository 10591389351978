// import '../../../css/SmartSecurityStyles.css';
import React from 'react'
import { sprintf } from 'sprintf-js'
import PropTypes from 'prop-types'
import CardItem from '../CardItem'
import CardItemContent from '../CardItemContent'
import PrinterIcon from '../PrinterIcon'
import BadgeIcon from '../BadgeIcon'

const Web = ({ isActive, isUnknown, strings, name }) => {
  return (
    <CardItem
      leftItem={
        <div style={{ position: 'relative', left: '0', top: '0' }}>
          <PrinterIcon />
          {isUnknown || !isActive ? '' : <BadgeIcon />}
        </div>
      }
      contentItem={<CardItemContent title={sprintf(strings.title, name)} />}
    />
  )
}

export default Web

Web.propTypes = {
  isActive: PropTypes.bool.isRequired,
  isUnknown: PropTypes.bool.isRequired,
  strings: PropTypes.object.isRequired,
  name: PropTypes.string.isRequired
}
