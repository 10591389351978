import { useState, useEffect, useContext } from 'react'
import {
  AssessmentResult,
  SecurityAssessmentState,
  AssessmentStatus,
  SecurityAssessmentStatus
} from '../static/consts'
import AssessmentContext from '../contexts/AssessmentContext'

function getValuesFromAssessment(assessment) {
  function _compare(s1, s2) {
    return s1 && s2 && s1.toLowerCase() === s2.toLowerCase()
  }

  const securityAssessmentStatus = assessment.securityAssessmentstatus || {}
  // securityAssessmentStatus
  const active = String(securityAssessmentStatus.active || 'false')
  const securityAssessmentState =
    securityAssessmentStatus.securityAssessmentState || ''
  const assessmentStatus = securityAssessmentStatus.assessmentStatus || ''
  const assessmentResult =
    securityAssessmentStatus.assessmentResult || AssessmentResult.unknown
  const lastAssessed = securityAssessmentStatus.lastAssessed || ''
  const securityPolicy = securityAssessmentStatus.securityPolicy || ''
  // assessmentStatus
  const isAssessmentInProgress = _compare(
    assessmentStatus,
    AssessmentStatus.inProgressAssessment
  )
  const isRemediationInProgress = _compare(
    assessmentStatus,
    AssessmentStatus.inProgressRemediation
  )
  const isComplete = _compare(assessmentStatus, AssessmentStatus.complete)
  // assessmentResult
  const isProtected = _compare(
    assessmentResult,
    AssessmentResult.securityMonitored
  )
  const isNotProtected = _compare(
    assessmentResult,
    AssessmentResult.needsAttention
  )
  const isSecuredDisabled = _compare(
    assessmentResult,
    AssessmentResult.notMonitored
  )
  const isUnknown = _compare(assessmentResult, AssessmentResult.unknown)
  // securityAssessmentState
  const isEnabled = _compare(
    securityAssessmentState,
    SecurityAssessmentState.enabled
  )
  const isDisabled = _compare(
    securityAssessmentState,
    SecurityAssessmentState.disabled
  )
  const isActive = active === SecurityAssessmentStatus.trueValue

  return {
    active,
    isActive,
    securityAssessmentState,
    assessmentStatus,
    assessmentResult,
    lastAssessed,
    securityPolicy,
    isAssessmentInProgress,
    isRemediationInProgress,
    isComplete,
    isProtected,
    isNotProtected,
    isSecuredDisabled,
    isUnknown,
    isEnabled,
    isDisabled
  }
}

export const useSecurityAssessmentStatus = () => {
  const { assessment } = useContext(AssessmentContext)
  const [result, setResult] = useState(
    getValuesFromAssessment(assessment || {})
  )

  useEffect(() => {
    setResult(getValuesFromAssessment(assessment || {}))
  }, [assessment])

  return result
}
