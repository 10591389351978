/* eslint-disable */
import en from './en.json'
import en_US from './en_US.json'
import hu_HU from './hu_HU.json'
import pl_PL from './pl_PL.json'
import cs_CZ from './cs_CZ.json'
import el_GR from './el_GR.json'
import ro_RO from './ro_RO.json'
import sk_SK from './sk_SK.json'
import hr_HR from './hr_HR.json'
import bg_BG from './bg_BG.json'
import lv_LV from './lv_LV.json'
import lt_LT from './lt_LT.json'
import et_EE from './et_EE.json'
import ar_SA from './ar_SA.json'
import da_DK from './da_DK.json'
import pt_BR from './pt_BR.json'
import fr_CA from './fr_CA.json'
import fr_FR from './fr_FR.json'
import zh_CN from './zh_CN.json'
import zh_TW from './zh_TW.json'
import nl_BE from './nl_BE.json'
import fi_FI from './fi_FI.json'
import de_DE from './de_DE.json'
import he_IL from './he_IL.json'
import it_IT from './it_IT.json'
import ja_JP from './ja_JP.json'
import ko_KR from './ko_KR.json'
import nb_NO from './nb_NO.json'
import ru_RU from './ru_RU.json'
import sl_SI from './sl_SI.json'
import sv_SE from './sv_SE.json'
import tr_TR from './tr_TR.json'
import es_ES from './es_ES.json'
import pt_PT from './pt_PT.json'
import nl_NL from './nl_NL.json'

export default {
  en,
  en_US,
  hu_HU,
  pl_PL,
  cs_CZ,
  el_GR,
  ro_RO,
  sk_SK,
  hr_HR,
  bg_BG,
  lv_LV,
  lt_LT,
  et_EE,
  ar_SA,
  da_DK,
  pt_BR,
  fr_CA,
  fr_FR,
  zh_TW,
  zh_CN,
  nl_BE,
  nl_NL,
  nb_NO,
  es_ES,
  he_IL,
  it_IT,
  ja_JP,
  ko_KR,
  pt_PT,
  ru_RU,
  sl_SI,
  sv_SE,
  tr_TR,
  fi_FI,
  de_DE
}

export const redundantLocales = {
  en_PR: 'en_US',
  en_IS: 'en_US',
  en_BZ: 'en_US',
  en_CR: 'en_US',
  en_VI: 'en_US',
  en_SX: 'en_US',
  en_BS: 'en_US',
  en_JM: 'en_US',
  en_KY: 'en_US',
  en_TC: 'en_US',
  en_AG: 'en_US',
  en_LC: 'en_US',
  en_VC: 'en_US',
  en_GD: 'en_US',
  en_BB: 'en_US',
  en_TT: 'en_US',
  en_MS: 'en_US',
  en_AI: 'en_US',
  en_KN: 'en_US',
  en_RS: 'en_US',
  en_ZA: 'en_US',
  en_NG: 'en_US',
  en_AL: 'en_US',
  en_BA: 'en_US',
  en_ME: 'en_US',
  en_AZ: 'en_US',
  en_BW: 'en_US',
  en_CM: 'en_US',
  en_ET: 'en_US',
  en_GE: 'en_US',
  en_GH: 'en_US',
  en_KE: 'en_US',
  en_MK: 'en_US',
  en_MU: 'en_US',
  en_NA: 'en_US',
  en_RW: 'en_US',
  en_TZ: 'en_US',
  en_UG: 'en_US',
  en_ZM: 'en_US',
  en_IN: 'en_US',
  en_BD: 'en_US',
  en_LK: 'en_US',
  en_SG: 'en_US',
  en_MY: 'en_US',
  en_TH: 'en_US',
  en_PH: 'en_US',
  en_ID: 'en_US',
  en_VN: 'en_US',
  en_PK: 'en_US',
  en_MM: 'en_US',
  en_LA: 'en_US',
  en_KH: 'en_US',
  en_BN: 'en_US',
  en_TL: 'en_US',
  en_NP: 'en_US',
  en_MV: 'en_US',
  en_MN: 'en_US',
  en_AF: 'en_US',
  en_BT: 'en_US',
  en_CY: 'en_US',
  en_IL: 'en_US',
  en_VG: 'en_US',
  en_JP: 'en_US',
  en_AE: 'en_US',
  en_SA: 'en_US',
  en_TN: 'en_US',
  en_MA: 'en_US',
  en_DZ: 'en_US',
  en_BH: 'en_US',
  en_EG: 'en_US',
  en_JO: 'en_US',
  en_KW: 'en_US',
  en_LB: 'en_US',
  en_LY: 'en_US',
  en_OM: 'en_US',
  en_QA: 'en_US',
  en_TW: 'en_US',
  en_HK: 'en_US',
  en_AW: 'en_US',
  es_MX: 'es_ES',
  es_PY: 'es_ES',
  es_UY: 'es_ES',
  es_BO: 'es_ES',
  es_VE: 'es_ES',
  es_EC: 'es_ES',
  es_CL: 'es_ES',
  es_PE: 'es_ES',
  es_AR: 'es_ES',
  es_CO: 'es_ES',
  es_BZ: 'es_ES',
  es_SV: 'es_ES',
  es_GT: 'es_ES',
  es_HN: 'es_ES',
  es_NI: 'es_ES',
  es_PA: 'es_ES',
  es_DO: 'es_ES',
  es_CU: 'es_ES',
  es_PR: 'es_ES',
  fr_HT: 'fr_FR',
  fr_GP: 'fr_FR',
  fr_MQ: 'fr_FR',
  fr_MF: 'fr_FR',
  fr_BL: 'fr_FR',
  fr_LC: 'fr_FR',
  fr_BJ: 'fr_FR',
  fr_CI: 'fr_FR',
  fr_GF: 'fr_FR',
  fr_MG: 'fr_FR',
  fr_YT: 'fr_FR',
  fr_NC: 'fr_FR',
  fr_RE: 'fr_FR',
  fr_SN: 'fr_FR',
  fr_PF: 'fr_FR',
  nl_CW: 'nl_NL',
  nl_BQ: 'nl_NL',
  de_LI: 'de_DE',
  de_LU: 'de_DE',
  ru_KZ: 'ru_RU',
  ru_BY: 'ru_RU',
  ru_UA: 'ru_RU',
  ru_UZ: 'ru_RU',
  pt_AO: 'pt_PT',
  pt_MZ: 'pt_PT',
  sv_FI: 'sv_SE'
}

export const FallbackCountries = {
  bg: 'BG',
  cs: 'CZ',
  da: 'DK',
  de: 'DE',
  el: 'GR',
  en: 'US',
  es: 'ES',
  et: 'EE',
  fi: 'FI',
  fr: 'CA',
  hr: 'HR',
  hu: 'HU',
  it: 'IT',
  lt: 'LT',
  lv: 'LV',
  nl: 'NL',
  nb: 'NO',
  pl: 'PL',
  pt: 'PT',
  ro: 'RO',
  sk: 'SK',
  sl: 'SI',
  sv: 'SE',
  ja: 'JP',
  ru: 'RU',
  tr: 'TR',
  zh: 'CN',
  ko: 'KR'
}
