import styled from 'styled-components'

export const PrinterBadge = styled.span`
  display: grid;
  grid-template-rows: 1fr;
  grid-template-columns: 1fr;
  position: absolute;
  bottom: 17%;
  right: 4%;
  display: -ms-grid;
  -ms-grid-rows: 1fr;
  -ms-grid-columns: 1fr;
`
