import React, { useContext } from 'react'
import { stringsForState } from '../../strings/MonitorSecuritySettings'
import AuthContext from '../../contexts/AuthContext'
import AssessmentHooksContext from '../../contexts/AssessmentHooksContext'
import Mobile from './Mobile'
import Web from './Web'

const MonitorSecuritySettings = () => {
  const {
    useSecurityAssessmentStatusHook,
    useAssessmentStringsHook,
    useRequestsHook
  } = useContext(AssessmentHooksContext)
  const {
    active,
    isEnabled,
    isProtected,
    assessmentResult,
    isAssessmentInProgress
  } = useSecurityAssessmentStatusHook
  const { state, onToggleMonitoring, showSecurityModal, dismissSecurityModal } =
    useRequestsHook
  const { isMobileSize } = useContext(AuthContext)
  const { monitorSecuritySettingsStrings } = useAssessmentStringsHook

  const strings = stringsForState(
    assessmentResult,
    isEnabled,
    monitorSecuritySettingsStrings
  )

  const componentProps = {
    active,
    isEnabled,
    isProtected,
    isAssessmentInProgress,
    state,
    onToggleMonitoring,
    showSecurityModal,
    dismissSecurityModal,
    isMobileSize,
    strings
  }

  return isMobileSize ? (
    <Mobile {...componentProps} />
  ) : (
    <Web {...componentProps} />
  )
}

export default MonitorSecuritySettings
