import React from 'react'

const NetworkErrorImg = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="88"
      height="88"
      viewBox="0 0 88 88"
    >
      <defs></defs>
      <rect style={{ fill: 'none' }} width="88" height="88" />
      <g transform="translate(7.333 20.165)">
        <path
          style={{ fill: '#4e4e4e' }}
          d="M66.37,29.76v-.367A18.155,18.155,0,0,0,48.037,11.06,18.49,18.49,0,0,0,32.27,20.227a12.361,12.361,0,0,0-6.233-1.833,11.031,11.031,0,0,0-11,11v.367A14.723,14.723,0,0,0,18.7,58.727h44A14.71,14.71,0,0,0,77.37,44.06,15.3,15.3,0,0,0,66.37,29.76ZM62.7,55.977h-44a11.973,11.973,0,0,1-3.038-23.54l2.121-.5V29.393a8.259,8.259,0,0,1,8.25-8.25,9.6,9.6,0,0,1,4.818,1.441L33.213,24l1.415-2.358A15.639,15.639,0,0,1,48.037,13.81,15.418,15.418,0,0,1,63.62,29.393v2.424l1.973.581A12.542,12.542,0,0,1,74.62,44.06,11.93,11.93,0,0,1,62.7,55.977Z"
          transform="translate(-4.037 -9.43)"
        />
        <g transform="translate(46.343 0)">
          <path
            style={{ fill: '#fff' }}
            d="M38.982,10.171a13.739,13.739,0,0,0-4.767.849h9.532A13.736,13.736,0,0,0,38.982,10.171Z"
            transform="translate(-25.232 -10.171)"
          />
          <path
            style={{ fill: '#fff' }}
            d="M44.639,19.663a13.744,13.744,0,0,0-.713-4.382v8.763A13.744,13.744,0,0,0,44.639,19.663Z"
            transform="translate(-17.139 -5.913)"
          />
          <path
            style={{ fill: '#fff' }}
            d="M29.315,19.844a13.69,13.69,0,0,0,.854,4.781V15.063A13.69,13.69,0,0,0,29.315,19.844Z"
            transform="translate(-29.315 -6.094)"
          />
          <path
            style={{ fill: '#fff' }}
            d="M38.815,25.5a13.738,13.738,0,0,0,4.4-.719h-8.8A13.741,13.741,0,0,0,38.815,25.5Z"
            transform="translate(-25.065 2.002)"
          />
          <path
            style={{ fill: 'none' }}
            d="M37.91,10.634H29.781v8.12A13.789,13.789,0,0,1,37.91,10.634Z"
            transform="translate(-28.927 -9.785)"
          />
          <path
            style={{ fill: 'none' }}
            d="M47.686,19.154v-8.52H39.414A13.8,13.8,0,0,1,47.686,19.154Z"
            transform="translate(-20.899 -9.785)"
          />
          <path
            style={{ fill: 'none' }}
            d="M47.853,20.061a13.793,13.793,0,0,1-8.639,8.65h8.639Z"
            transform="translate(-21.066 -1.929)"
          />
          <path
            style={{ fill: 'none' }}
            d="M29.781,20.279v8.25h8.5A13.789,13.789,0,0,1,29.781,20.279Z"
            transform="translate(-28.927 -1.748)"
          />
          <path
            style={{ fill: '#fff' }}
            d="M55.714,27.917V19.154a13.8,13.8,0,0,0-8.272-8.52H37.91a13.789,13.789,0,0,0-8.129,8.12v9.563a13.789,13.789,0,0,0,8.5,8.25h8.8A13.793,13.793,0,0,0,55.714,27.917ZM42.759,34.405A10.8,10.8,0,1,1,53.565,23.6,10.8,10.8,0,0,1,42.759,34.405Z"
            transform="translate(-28.927 -9.785)"
          />
          <path
            style={{ fill: '#be1313' }}
            d="M41.772,11.813A10.8,10.8,0,1,0,52.577,22.619,10.8,10.8,0,0,0,41.772,11.813Zm5.689,11.656H35.78a.917.917,0,0,1,0-1.833h11.68a.917.917,0,1,1,0,1.833Z"
            transform="translate(-27.939 -8.803)"
          />
          <path
            style={{ fill: '#fff' }}
            d="M45.689,17.171H34.009a.917.917,0,0,0,0,1.833h11.68a.917.917,0,1,0,0-1.833Z"
            transform="translate(-26.168 -4.338)"
          />
        </g>
      </g>
    </svg>
  )
}
export default NetworkErrorImg
