export const SHOW_SECURITY_MODAL = 'SHOW_SECURITY_MODAL'
export const DISMISS_SECURITY_MODAL = 'DISMISS_SECURITY_MODAL'
export const SET_MONITORING_IN_PROGRESS = 'SET_MONITORING_IN_PROGRESS'
export const FINISH_MONITORING_IN_PROGRESS = 'FINISH_MONITORING_IN_PROGRESS'

export const showModal = () => {
  return {
    type: SHOW_SECURITY_MODAL
  }
}

export const dismissModal = () => {
  return {
    type: DISMISS_SECURITY_MODAL
  }
}

export const setMonitoringInProgress = () => {
  return {
    type: SET_MONITORING_IN_PROGRESS
  }
}

export const finishMonitoringInProgress = () => {
  return {
    type: FINISH_MONITORING_IN_PROGRESS
  }
}
