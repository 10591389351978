import React from 'react'
import Button from '@veneer/core/dist/scripts/button'
import PropTypes from 'prop-types'
import NetworkErrorImg from './NetworkErrorImg'

const FailToLoad = ({
  message,
  btnLabel,
  btnAction,
  btnLoading,
  showButton
}) => {
  const margin = { margin: 'auto' }
  const center = { ...margin, width: 'min-content' }

  return (
    <div style={margin} className="ss-network-error">
      <div style={center} data-testid="ss-clour-error-image">
        <NetworkErrorImg />
      </div>
      <h6
        className="list-item__middle-section--primary subcontent"
        style={{
          ...margin,
          textAlign: 'center',
          padding: '0 0 24px 0'
        }}
        data-testid="ss-failToLoad-content"
      >
        {message}
      </h6>
      {showButton && (
        <div style={center}>
          <Button
            customStyle={margin}
            onClick={btnAction}
            loading={btnLoading}
            data-testid="ss-failToLoad-button"
          >
            {btnLabel}
          </Button>
        </div>
      )}
    </div>
  )
}

export default FailToLoad

FailToLoad.propTypes = {
  message: PropTypes.string.isRequired,
  btnLabel: PropTypes.string.isRequired,
  btnAction: PropTypes.func.isRequired,
  btnLoading: PropTypes.bool,
  showButton: PropTypes.bool
}

FailToLoad.defaultProps = {
  btnLoading: false,
  showButton: false
}
