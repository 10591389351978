export const registerAnalyticsEvents = (linkIDAnalytics) => {
  if (window.Portal) {
    window.Portal.analytics.registerClickEvent({
      linkID: linkIDAnalytics,
      linkPlacement: 'smart-security'
    })
  }
}
declare global {
  interface Window {
    Portal: any
  }
}
