import React from 'react'
import Toggle from '@veneer/core/dist/scripts/toggle'
import Button from '@veneer/core/dist/scripts/button'
import Modal from '@veneer/core/dist/scripts/modal'
import ProgressIndicator from '@veneer/core/dist/scripts/progress_indicator'
// import { LogoHpPlus } from '@veneer/core/dist/scripts/logos'
import PropTypes from 'prop-types'
import CardItem from '../CardItem'
import CardItemContent from '../CardItemContent'
import WarningMarker from '../WarningMarker'
import { SecurityAssessmentStatus } from '../../static/consts'
import EmptyLeftItem from '../EmptyLeftItem'

const Web = ({
  active,
  strings,
  isEnabled,
  isAssessmentInProgress,
  state,
  onToggleMonitoring,
  showSecurityModal,
  dismissSecurityModal
}) => {
  const rightItem = () => {
    if (state.isMonitoringInProgress || isAssessmentInProgress) {
      return <ProgressIndicator data-testid="ss-progress-indicator" />
    }
    if (active === SecurityAssessmentStatus.trueValue) {
      return (
        <Toggle
          on={isEnabled}
          disabled={state.isMonitoringInProgress}
          onChange={showSecurityModal}
          id="toggle"
          data-testid="ss-monitor-toggle"
        />
      )
    }
    return <></>
  }

  return (
    <>
      <Modal
        id="ss-modal"
        align="center"
        onClose={dismissSecurityModal}
        show={state.isShowingModal}
        title={strings.modalTitle}
        data-testid="ss-monitorModal"
        footer={
          <div className="vn-button-group--responsive">
            <Button
              // style={{
              //   margin: '10px'
              // }}
              onClick={isEnabled ? dismissSecurityModal : onToggleMonitoring}
              data-testid="ss-monitorModal-security-button"
            >
              {strings.buttonPrimaryTitle}
            </Button>
            <Button
              appearance="secondary"
              onClick={isEnabled ? onToggleMonitoring : dismissSecurityModal}
              data-testid="ss-monitorModal-removeSecurity-button"
            >
              {strings.buttonSecondaryTitle}
            </Button>
          </div>
        }
      >
        <p>{strings.modalDescription}</p>
      </Modal>
      <CardItem
        leftItem={
          <>
            <EmptyLeftItem />
            <WarningMarker isSecured={isEnabled} />
          </>
        }
        contentItem={
          <CardItemContent
            title={strings.title}
            titleStyle={{ marginLeft: '14px' }}
            customCss={{ marginLeft: '14px' }}
            content={<>{strings.description}</>}
          />
        }
        rightItem={rightItem()}
      />
    </>
  )
}

export default Web

Web.propTypes = {
  active: PropTypes.string.isRequired,
  strings: PropTypes.object.isRequired,
  isEnabled: PropTypes.bool.isRequired,
  isAssessmentInProgress: PropTypes.bool.isRequired,
  state: PropTypes.object.isRequired,
  onToggleMonitoring: PropTypes.func.isRequired,
  showSecurityModal: PropTypes.func.isRequired,
  dismissSecurityModal: PropTypes.func.isRequired
}
