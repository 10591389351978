export const getbaseUrl = () => {
  return `${window.location.protocol}//api.${window.location.hostname}`
}

const environmentVariables = {
  local: {
    deviceApiUrl: 'http://localhost:3001/devicecache',
    clientId: 'g1PC7Nw3GtkekSaPOKBrEoVkw9dKbpjo',
    // deviceApiUrl: 'http://localhost:3001',
    ssApiUrl: 'http://localhost:3001',
    printPlanHyperLink: 'https://www.hpsmartpie.com/us/en/ucde/print_plans'
  },
  proxy: {
    deviceApiUrl: 'http://localhost:3001',
    clientId: 'g1PC7Nw3GtkekSaPOKBrEoVkw9dKbpjo',
    // deviceApiUrl: 'http://localhost:3002',
    ssApiUrl: 'https://smartsecurity-pie.tropos-rnd.com/securitymgr',
    printPlanHyperLink: 'https://www.hpsmartpie.com/us/en/ucde/print_plans'
  },
  dev: {
    deviceApiUrl: 'https://pie-us1.api.ws-hp.com/devicecache',
    clientId: 'g1PC7Nw3GtkekSaPOKBrEoVkw9dKbpjo',
    // deviceApiUrl: window.location.origin.includes('myaccount')
    //   ? getbaseUrl()
    //   : 'https://www.hpsmartdev.com',
    ssApiUrl: 'https://pie-us1.api.ws-hp.com/securitymgr',
    printPlanHyperLink: 'https://www.hpsmartpie.com/us/en/ucde/print_plans'
  },
  pie: {
    deviceApiUrl: 'https://pie-us1.api.ws-hp.com/devicecache',
    clientId: 'g1PC7Nw3GtkekSaPOKBrEoVkw9dKbpjo',
    // deviceApiUrl: window.location.origin.includes('myaccount')
    //   ? getbaseUrl()
    //   : 'https://www.hpsmartpie.com',
    ssApiUrl: 'https://pie-us1.api.ws-hp.com/securitymgr',
    printPlanHyperLink: 'https://www.hpsmartpie.com/us/en/ucde/print_plans'
  },
  stage: {
    deviceApiUrl: 'https://stage-us1.api.ws-hp.com/devicecache',
    clientId: 'g1PC7Nw3GtkekSaPOKBrEoVkw9dKbpjo',
    // deviceApiUrl: window.location.origin.includes('myaccount')
    //   ? getbaseUrl()
    //   : window.location.origin, //'https://www.hpsmartstage.com'
    ssApiUrl: 'https://stage-us1.api.ws-hp.com/securitymgr',
    printPlanHyperLink: 'https://www.hpsmartstage.com/us/en/ucde/print_plans'
  },
  prod: {
    deviceApiUrl: 'https://us1.api.ws-hp.com/devicecache',
    clientId: 'g1PC7Nw3GtkekSaPOKBrEoVkw9dKbpjo',
    // deviceApiUrl: window.location.origin.includes('myaccount')
    //   ? getbaseUrl()
    //   : window.location.origin, //'https://www.hpsmart.com'
    ssApiUrl: 'https://us1.api.ws-hp.com/securitymgr',
    printPlanHyperLink: 'https://www.hpsmart.com/ucde/print_plans'
  }
}

export function getEnvConfig(env) {
  // const tlcEnv = env?.toLowerCase()
  switch (env) {
    case 'local':
      return environmentVariables.local
    case 0:
      return environmentVariables.dev
    case 1:
      return environmentVariables.pie
    case 2:
      return environmentVariables.stage
    case 3:
      return environmentVariables.prod
    default:
      return environmentVariables.pie
  }

  // switch (tlcEnv) {
  //   case 'local':
  //     return environmentVariables.local
  //   case 'proxy':
  //     return environmentVariables.proxy
  //   case 'stage':
  //   case 'stg':
  //     return environmentVariables.stage
  //   case 'prod':
  //   case 'production':
  //     return environmentVariables.prod
  //   case 'pie':
  //     return environmentVariables.pie
  //   case 'dev':
  //     return environmentVariables.dev
  //   default:
  //     return environmentVariables.pie
  // }
}

//gen2-flex , gen2Yeti - both, (gen1, stratus- no bizmodel)
export const acceptedDeviceModelsList = ['gen1', 'gen2', 'gen2Yeti', 'stratus']

export const PlatformIdentifier = {
  GEN1: 'gen1',
  GEN2: 'gen2',
  GEN2YETI: 'gen2yeti',
  STRATUS: 'stratus'
}

export const bizModel = {
  E2E: 'e2e',
  FLEX: 'flex'
}

export const UserTypes = {
  OWNER_TENANT: 'admin',
  USER_TENANT: 'tenant'
}

export const DevicePrintPlanSubscriptionStatus = {
  SUBSCRIBED: 'subscribed',
  ELIGIBLE: 'eligible',
  NOTELIGIBLE: 'noteligible'
}

export const AnalyticsClickEvents = {
  ENABLE_MONITORING: 'enable-security-monitoring',
  DISABLE_MONITORING: 'disable-security-monitoring',
  DISMISS_ENABLE_MONITORING: 'dismiss-enable-security-monitoring',
  DISMISS_DISABLE_MONITORING: 'dismiss-disable-security-monitoring',
  SETPASSWORD: 'set-printer-password',
  ENABLE_AUTOFIRMWAREUPDATE: 'enable-auto-firmware-update',
  DISABLE_AUTOFIRMWAREUPDATE: 'disable-auto-firmware-update',
  EXPAND_PROTECTION_STATUS: 'expand-protection-status-dropdown',
  SHRINK_PROTECTION_STATUS: 'shrink-protection-status-dropdown'
}

export const DeviceConfigurationsId = {
  CONFIG_ADMIN_PASSWORD_SET: 'configAdminPasswordSet',
  CONFIG_WIFI_NETWORK: 'configWifi0Network',
  CONFIG_AUTO_FW_UPDATE: 'configAutoFWUpdate'
}

export const DeviceConfigurationsValue = {
  trueValue: 'true',
  falseValue: 'false',
  unknownValue: 'unknown'
}

export const DeviceConfigurationResult = {
  compliant: 'compliant',
  nonCompliant: 'noncompliant',
  complianceOff: 'complianceoff',
  unknown: 'unknown'
}

export const AssessmentResult = {
  securityMonitored: 'securitymonitored',
  needsAttention: 'needsattention',
  notMonitored: 'notmonitored',
  unknown: 'unknown'
}

export const AssessmentStatus = {
  unknown: 'unknown',
  inProgressAssessment: 'inprogress_assessment',
  inProgressRemediation: 'inprogress_remediation',
  complete: 'complete'
}

export const RemediationState = {
  completed: 'completed',
  inprogress: 'inprogress',
  unknown: 'unknown'
}
export const RemediationResults = {
  ERROR: 'error',
  SUCCESS: 'success'
}

export const SecurityAssessmentStatus = {
  trueValue: 'true',
  falseValue: 'false'
}

export const SecurityAssessmentState = {
  enabled: 'enabled',
  disabled: 'disabled'
}

export const Sizes = {
  ICON_SIZE: 36,
  BADGE_SIZE: 36,
  MOBILE_VERSION_MAX_WIDTH: 576,
  CHEVRON_SIZE: 36,
  INFO_SIZE: 36,
  MOBILE_WARNING_SIZE: 36
}

export const TIMEOUT = 40 * 1000

export const PasswordColors = {
  compliant: {
    text: {
      color: 'green'
    },
    border: {
      borderColor: 'green'
    }
  },
  noncompliant: {
    text: {
      color: '#be1313'
    },
    border: {
      borderColor: '#be1313'
    }
  }
}

export const BadgeColors: any = {
  lightGreen: 'colorLightGreen6',
  orange: 'colorOrange6'
}

export const ToastIDs = {
  setAdminPassword: {
    POSITIVE: 'setAdminPasswordOnToast',
    NEGATIVE: 'errorSetAdminPasswordOnToast'
  },
  setAutoFWUpdate: {
    POSITIVE: 'setAutoFwUpdateOnToast',
    NEGATIVE: 'errorSetAutoFwUpdateOnToast'
  },
  setMonitoringSecurity: {
    POSITIVE: 'setMonitoringOnToast',
    NEGATIVE: 'errorSetMonitoringOnToast'
  },
  setRemediationCheck: {
    POSITIVE: 'setRemediationCheckOnToast',
    NEGATIVE: 'errorSetRemediationCheck'
  }
}

export const ToastTypes: any = {
  POSITIVE: 'positive',
  NEGATIVE: 'negative'
}

export const AppColors = {
  warning: '#E8701A'
}

export const defaultPrinterIcon =
  'https://s3-us-west-2.amazonaws.com/wpp-printers-images-prod/5f0a80bd7299dcedd856d87dd30a9f7b/170x128.png'

export const ConstraintsDataTypesKeys = {
  power: 'power',
  firmware: 'firmware',
  memory: 'memory',
  network: 'network'
}

// Constraints are displayed in the following order:
export const ConstraintsDataTypes = [
  ConstraintsDataTypesKeys.power,
  ConstraintsDataTypesKeys.firmware,
  ConstraintsDataTypesKeys.memory,
  ConstraintsDataTypesKeys.network
]

export const ConfigControlsIDs = {
  configWifiNetworkEncryptionType: 'controlWifi0Network_EncryptionType'
}

export const Colors: any = {
  MOBILE_INFO_BLUE: 'colorHpBlue6'
}

export const HelperLinks = {
  link1: (stack) => {
    let baseUrl = 'https://www.hpsmart.com'
    switch (stack) {
      case 'stage':
      case 'stg':
        baseUrl = 'https://www.hpsmartstage.com'
        break
      case 'prod':
      case 'production':
        baseUrl = 'https://www.hpsmart.com'
        break
      case 'dev':
        baseUrl = 'https://www.hpsmartdev.com'
        break
      case 'pie':
      default:
        baseUrl = 'https://www.hpsmartpie.com'
        break
    }

    return `${baseUrl}/ucde/help/hp-plus/hpsmartsecurity`
  },
  link2:
    'https://support.hp.com/in-en/topic/diagnostics?jumpid=in_r11839_hk/en/covid/diag'
}
