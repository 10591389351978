import React, { useContext, useEffect, useState } from 'react'
import AuthContext from '../../contexts/AuthContext'
import AssessmentHooksContext from '../../contexts/AssessmentHooksContext'
import Web from './Web'
import Mobile from './Mobile'
import { stringsForState } from '../../strings/DeviceConfigurations/passwordProtectionStatusStrings'
import UpdateInProgressCard from '../UpdateInProgressCard'

const PasswordProtectionStatus = () => {
  const {
    useDeviceConfigurationsAssessmentHook,
    useSecurityAssessmentStatusHook,
    useAssessmentStringsHook,
    useSetAdminPasswordHook,
    useRemediationHook
  } = useContext(AssessmentHooksContext)
  const { isMobileSize } = useContext(AuthContext)
  const { configAdminPasswordSet } = useDeviceConfigurationsAssessmentHook
  const { configAdminPasswordSetStrings } = useAssessmentStringsHook

  const setAdminPassword = useSetAdminPasswordHook
  const { password, isCompliant, isLoading, isPwdUnknownValue } =
    setAdminPassword
  const { remediationConfigAdminPasswordSet } = useRemediationHook
  const { isComplete } = useSecurityAssessmentStatusHook

  const strings = stringsForState(
    configAdminPasswordSetStrings,
    configAdminPasswordSet
  )

  const [isInProgress, setInProgress] = useState(false)

  const loadingCard = <UpdateInProgressCard />

  const componentProps = {
    remediationConfigAdminPasswordSet,
    configAdminPasswordSet,
    strings,
    setAdminPassword,
    password,
    isMobileSize,
    isCompliant,
    isInProgress,
    loadingCard,
    isPwdUnknownValue
  }

  useEffect(() => {
    if (isLoading || remediationConfigAdminPasswordSet.isLoading) {
      setInProgress(true)
    } else if (isComplete) {
      setInProgress(false)
    }
  }, [isLoading, remediationConfigAdminPasswordSet, isComplete])

  return isMobileSize ? (
    <Mobile {...componentProps} />
  ) : (
    <Web {...componentProps} />
  )
}

export default PasswordProtectionStatus
