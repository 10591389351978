import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useAssessment } from '../hooks/useAssessment'
import { useAssessmentStrings } from '../hooks/useAssessmentStrings'
import { useDeviceConfigurationsAssessment } from '../hooks/useDeviceConfigurationsAssessment'
import { useRequests } from '../hooks/useRequests'
import { useSecurityAssessmentStatus } from '../hooks/useSecurityAssessmentStatus'
import { useSetAdminPassword } from '../hooks/useSetAdminPassword'
import { useRemediation } from '../hooks/useRemediation'
import AssessmentContext from '../contexts/AssessmentContext'
import AssessmentHooksContext from '../contexts/AssessmentHooksContext'

const withAssessmentHooks = (Component) => {
  const NewComponent = (props) => {
    const useDeviceConfigurationsAssessmentHook =
      useDeviceConfigurationsAssessment()
    const useSecurityAssessmentStatusHook = useSecurityAssessmentStatus()

    const useAssessmentStringsHook = useAssessmentStrings(
      useDeviceConfigurationsAssessmentHook
    )
    const useRequestsHook = useRequests({
      useAssessmentStringsHook,
      isEnabled: useSecurityAssessmentStatusHook.isEnabled
    })

    const useRemediationHook = useRemediation(useSecurityAssessmentStatusHook)

    const useSetAdminPasswordHook = useSetAdminPassword({
      configAdminPasswordSet:
        useDeviceConfigurationsAssessmentHook.configAdminPasswordSet,
      useAssessmentStringsHook
    })

    const values = {
      useRemediationHook,
      useAssessmentStringsHook,
      useDeviceConfigurationsAssessmentHook,
      useRequestsHook,
      useSecurityAssessmentStatusHook,
      useSetAdminPasswordHook
    }
    return (
      <AssessmentHooksContext.Provider value={values}>
        <Component {...props} />
      </AssessmentHooksContext.Provider>
    )
  }

  return NewComponent
}

export const withAssessment = (Component) => {
  const WithHooksComponent = withAssessmentHooks(Component)

  const NewComponent = (props) => {
    const { printer } = props
    const {
      assessment,
      hasLoadingError,
      errorMessage,
      showButton,
      loading,
      requestAssessment
    } = useAssessment({ printer })
    useEffect(() => {
      if (printer.showAssementDetails(true)) {
        requestAssessment()
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const [hasCallRemediation, setHasCallRemediation] = useState(false)

    const values = {
      errorMessage,
      showButton,
      assessment,
      loading,
      hasLoadingError,
      requestAssessment,
      printer,
      hasCallRemediation,
      setHasCallRemediation
    }
    return (
      <AssessmentContext.Provider value={values}>
        <WithHooksComponent {...props} />
      </AssessmentContext.Provider>
    )
  }

  NewComponent.propTypes = {
    printer: PropTypes.object.isRequired
  }
  return NewComponent
}

export default withAssessment
