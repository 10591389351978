import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import { tenantStringsForState } from '../../strings/MonitorSecuritySettings'
import AssessmentHooksContext from '../../contexts/AssessmentHooksContext'
import Web from './Web'
import Mobile from './Mobile'
import AuthContext from '../../contexts/AuthContext'

const TenantDevice = ({ showAssessment }) => {
  const { i18n, isMobileSize } = useContext(AuthContext)
  const { useAssessmentStringsHook, useSecurityAssessmentStatusHook } =
    useContext(AssessmentHooksContext)

  const { monitorSecuritySettingsStrings, configWifiNetworkStrings } =
    useAssessmentStringsHook

  const { isEnabled, assessmentResult } = useSecurityAssessmentStatusHook

  const strings = tenantStringsForState(
    assessmentResult,
    isEnabled,
    monitorSecuritySettingsStrings
  )

  const componentProps = {
    i18n,
    strings,
    showAssessment,
    isEnabled,
    monitorSecuritySettingsStrings,
    configWifiNetworkStrings
  }

  return isMobileSize ? (
    <Mobile {...componentProps} />
  ) : (
    <Web {...componentProps} />
  )
}

export default TenantDevice
TenantDevice.propTypes = {
  showAssessment: PropTypes.bool.isRequired
}
