import React from 'react'
// import '../../css/SmartSecurityStyles.css';
import PropTypes from 'prop-types'
import { navigateToPortal } from '../utils/navigate'

function HelperInfo({ i18n, mobileSize }) {
  function handleClick(e) {
    e.preventDefault()
    navigateToPortal()
  }

  return (
    <div className="ss-help-section">
      <h6 style={{ display: 'inline-block' }} data-testid="ss-help-title">
        <p>{i18n.t('help.title')}</p>
        {mobileSize || <hr className="divider" />}
      </h6>
      <div className="ss-helper-links">
        <span>
          <a href="#" onClick={handleClick} data-testid="ss-help-link">
            <p>{i18n.t('help.link1')}</p>
          </a>
        </span>
      </div>
    </div>
  )
}

export default HelperInfo

HelperInfo.propTypes = {
  i18n: PropTypes.object.isRequired,
  mobileSize: PropTypes.bool.isRequired
}
