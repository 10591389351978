export const printerStatusStrings = (i18n) => {
  const strings = {
    printerModel: i18n.t(`shared.printerModel`),
    titleInProgress: i18n.t(`shared.titleInProgress`)
  }
  return strings
}

export const stringsForState = (strings) => {
  const formattedStrings = { ...strings }
  formattedStrings.title = strings.printerModel
  return formattedStrings
}
