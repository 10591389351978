/* eslint-disable no-use-before-define */
import { useState, useContext, useEffect } from 'react'
import { useToast } from '@veneer/core'
import { getToastErrorStrings } from '../strings/Errors/index'
import AssessmentContext from '../contexts/AssessmentContext'
import AuthContext from '../contexts/AuthContext'
import {
  ToastIDs,
  ToastTypes,
  DeviceConfigurationsId,
  RemediationResults
} from '../static/consts'

export const useRemediation = (useSecurityAssessmentStatusHook) => {
  const RETRY_TIMEOUT = 3000
  const { printer, requestAssessment } = useContext(AssessmentContext)
  const { i18n, client } = useContext(AuthContext)
  const { isRemediationInProgress } = useSecurityAssessmentStatusHook
  const { addToast } = useToast()

  const [timerId, setTimerId] = useState(undefined)
  let remediationUpdateRequired = false
  let hasRequestInProgress = false

  const [remediationState, setRemediationState] = useState('')
  const [remediationResult, setRemediationResult] = useState('')
  const [remediationConfigAutoFWUpdate, setRemediationConfigAutoFWUpdate] =
    useState({})
  const [
    remediationConfigAdminPasswordSet,
    setRemediationConfigAdminPasswordSet
  ] = useState({})

  const isRemediationCompleted = (data) => {
    const remediation = data || {}
    return remediation.remediationstate === 'completed'
  }

  const updateRemediationStates = (data) => {
    const remediation = data || {}

    setRemediationState(remediation.remediationstate || '')
    setRemediationResult(remediation.remediationresult || '')

    const setConfig = (config, stateSetter) => {
      const theConfig =
        config && config.remediationdata ? config.remediationdata : {}
      const newConfig = {}
      newConfig.remediationState = theConfig.remediationstate || ''
      newConfig.remediationResult = theConfig.remediationresult || ''
      newConfig.remediationResultReason =
        theConfig.remediationresult_reason || ''
      newConfig.isLoading = newConfig.remediationState === 'inprogress'
      newConfig.isComplete = newConfig.remediationState === 'completed'
      stateSetter(newConfig)
    }

    const details = remediation.remediationstatus_detailed || []
    details.forEach((config) => {
      const theConfig = config || {}
      switch (theConfig.configItem) {
        case DeviceConfigurationsId.CONFIG_AUTO_FW_UPDATE:
          setConfig(config, setRemediationConfigAutoFWUpdate)
          break
        case DeviceConfigurationsId.CONFIG_ADMIN_PASSWORD_SET:
          setConfig(config, setRemediationConfigAdminPasswordSet)
          break
        default:
          break
      }
    })
  }

  const clearTimer = (t) => {
    if (t) {
      clearTimeout(t)
    }
  }

  const fetchRemediation = async () => {
    hasRequestInProgress = true
    remediationUpdateRequired = false
    try {
      const result = await client.getRemediationStatus(printer.deviceId)

      updateRemediationStates(result.data)
      hasRequestInProgress = false
      if (remediationUpdateRequired) {
        fetchRemediation()
      } else if (isRemediationCompleted(result.data)) {
        requestAssessment()
      } else {
        scheduleRemediationRefresh()
      }
    } catch (error) {
      hasRequestInProgress = false
      scheduleRemediationRefresh()
    }
  }

  const requestRemediation = () => {
    if (hasRequestInProgress) {
      remediationUpdateRequired = true
    } else {
      fetchRemediation()
    }
  }
  function scheduleRemediationRefresh() {
    const to = setTimeout(() => {
      requestRemediation()
    }, RETRY_TIMEOUT)

    setTimerId((t) => {
      clearTimer(t)
      return to
    })
  }
  useEffect(() => {
    if (isRemediationInProgress) {
      requestRemediation()
    } else {
      clearTimer(timerId)
    }

    return () => {
      clearTimer(timerId)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isRemediationInProgress])

  useEffect(() => {
    if (remediationResult === RemediationResults.ERROR) {
      const toastData = {
        id: ToastIDs.setRemediationCheck.NEGATIVE + printer.deviceId,
        type: ToastTypes.NEGATIVE,
        ...getToastErrorStrings({ errorCode: 'RemediationError' }, i18n)
      }
      addToast(toastData)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [remediationResult])
  return {
    remediationState,
    remediationResult,
    remediationConfigAutoFWUpdate,
    remediationConfigAdminPasswordSet
  }
}
