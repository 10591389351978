import { useContext, useEffect, useState } from 'react'
import AssessmentContext from '../contexts/AssessmentContext'
import { monitorSecuritySettingsStrings } from '../strings/MonitorSecuritySettings'
import { configurationStrings } from '../strings/DeviceConfigurations'
import { printerStatusStrings } from '../strings/PrinterStatus'
import AuthContext from '../contexts/AuthContext'

export const useAssessmentStrings = (useDeviceConfigurationsAssessmentHook) => {
  const { i18n } = useContext(AuthContext)
  const { assessment } = useContext(AssessmentContext)

  const deviceConfigurations = useDeviceConfigurationsAssessmentHook

  const allStrings = (i18n_) => {
    return {
      ...configurationStrings(i18n_, deviceConfigurations),
      monitorSecuritySettingsStrings: monitorSecuritySettingsStrings(i18n_),
      printerStatusStrings: printerStatusStrings(i18n_)
    }
  }

  const [strings, setStrings] = useState(allStrings(i18n))

  useEffect(() => {
    setStrings(allStrings(i18n))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [assessment, i18n, deviceConfigurations])

  return strings
}
