import { useState, useEffect } from 'react'
import { Sizes } from '../static/consts'

export const useMobileVersion = () => {
  const RESIZE = 'resize'
  const [isMobileSize, setIsMobileSize] = useState(
    window.innerWidth < Sizes.MOBILE_VERSION_MAX_WIDTH
  )

  useEffect(() => {
    const handleResize = () => {
      setIsMobileSize(window.innerWidth < Sizes.MOBILE_VERSION_MAX_WIDTH)
    }
    window.addEventListener(RESIZE, handleResize)

    return () => window.removeEventListener(RESIZE, handleResize)
  }, [])

  return { isMobileSize }
}
