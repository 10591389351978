import React from 'react'
import StatefulIcon from '../StatefulIcon'
import CardItem from '../CardItem'
import CardItemContent from '../CardItemContent'
import PasswordForm from './PasswordForm'

const Web = ({
  strings,
  setAdminPassword,
  isCompliant,
  isInProgress,
  loadingCard,
  isPwdUnknownValue
}) => {
  const regularContent = (
    <li className="ss-password-section">
      <CardItem
        leftItem={
          <div className="list-item__left-section">
            <StatefulIcon value={isCompliant} />
          </div>
        }
        contentItem={
          <CardItemContent
            title={strings.title}
            content={<>{strings.description}</>}
          />
        }
      />

      {!isCompliant && !isPwdUnknownValue ? (
        <PasswordForm useSetAdminPassword={setAdminPassword} />
      ) : (
        ''
      )}
    </li>
  )

  return isInProgress ? loadingCard : regularContent
}

export default Web
