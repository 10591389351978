import { DeviceConfigurationsValue } from '../../static/consts'

const safetyStatusStrings = (i18n, deviceConfiguration) => {
  return {
    titleGood: i18n.t(`configurations.${deviceConfiguration}.titleGood`),
    descriptionGood: i18n.t(
      `configurations.${deviceConfiguration}.descriptionGood`
    ),
    titleBad: i18n.t(`configurations.${deviceConfiguration}.titleBad`),
    descriptionBad: i18n.t(
      `configurations.${deviceConfiguration}.descriptionBad`
    ),
    titleUnknown: i18n.t(`configurations.${deviceConfiguration}.titleUnknown`),
    descriptionUnknown: i18n.t(
      `configurations.${deviceConfiguration}.descriptionUnknown`
    ),
    securityChecks: {
      power: {
        title: i18n.t(
          `configurations.${deviceConfiguration}.securityChecks.power.title`
        ),
        description: i18n.t(
          `configurations.${deviceConfiguration}.securityChecks.power.description`
        )
      },
      firmware: {
        title: i18n.t(
          `configurations.${deviceConfiguration}.securityChecks.firmware.title`
        ),
        description: i18n.t(
          `configurations.${deviceConfiguration}.securityChecks.firmware.description`
        ),
        descriptionFlex: i18n.t(
          `configurations.${deviceConfiguration}.securityChecks.firmware.descriptionFlex`
        )
      },
      memory: {
        title: i18n.t(
          `configurations.${deviceConfiguration}.securityChecks.memory.title`
        ),
        description: i18n.t(
          `configurations.${deviceConfiguration}.securityChecks.memory.description`
        )
      },
      network: {
        titleGood: i18n.t(
          `configurations.${deviceConfiguration}.securityChecks.network.titleGood`
        ),
        descriptionGood: i18n.t(
          `configurations.${deviceConfiguration}.securityChecks.network.descriptionGood`
        ),
        titleBad: i18n.t(
          `configurations.${deviceConfiguration}.securityChecks.network.titleBad`
        ),
        descriptionBad: i18n.t(
          `configurations.${deviceConfiguration}.securityChecks.network.descriptionBad`
        )
      }
    }
  }
}

export const stringsForState = (strings, configuration) => {
  let formattedStrings = { ...strings }

  switch (configuration.value) {
    case DeviceConfigurationsValue.trueValue:
      formattedStrings = {
        ...formattedStrings,
        title: strings.titleGood,
        description: strings.descriptionGood
      }
      break
    case DeviceConfigurationsValue.falseValue:
      formattedStrings = {
        ...formattedStrings,
        title: strings.titleBad,
        description: strings.descriptionBad
      }
      break
    case DeviceConfigurationsValue.unknownValue:
    default:
      formattedStrings = {
        ...formattedStrings,
        title: strings.titleUnknown,
        description: strings.descriptionUnknown
      }
      break
  }

  return formattedStrings
}

export default safetyStatusStrings
