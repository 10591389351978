import React from 'react'
// import '../../csscss/SmartSecurityStyles.css'
import PrinterList from '../PrinterList'
import SsClient from '../../client/SsClient'
import AuthContext from '../../contexts/AuthContext'
import createI18n from '../../utils/i18n'
import { getEnvConfig, TIMEOUT } from '../../static/consts'
import { useMobileVersion } from '../../hooks/useMobileVersion'
import LoadingPage from '../LoadingPage'
import HelperInfo from '../HelperInfo'
import useDevice from '../../hooks/useDevice'
import { ToastProvider } from '@veneer/core'

const SmartSecurity = ({
  stack,
  // userID,
  authProvider,
  language,
  country
}: any) => {
  const lang = `${language?.toLowerCase()}_${country?.toUpperCase()}`
  const i18n = createI18n(lang)

  let gateway = false
  let deviceID = undefined
  const url = window.location.href
  const pathname = window.location.pathname

  if (url?.includes('gateway')) {
    gateway = true
    deviceID = pathname.split('/').pop()
  }

  const {
    fetchPrinters,
    printers,
    loadingPrinters,
    isLoadingPageDetails,
    errorToLoad,
    setIsLoadingPageDetails
  } = useDevice({ stack, authProvider, deviceID })

  const config = getEnvConfig(stack)
  const { isMobileSize } = useMobileVersion()

  const toastPosition = isMobileSize ? 'top' : 'bottom'
  const ssClient = new SsClient(config.ssApiUrl, authProvider)
  const onVisibilityChanged = () => {
    setIsLoadingPageDetails(!isLoadingPageDetails)
  }

  return (
    <ToastProvider position={toastPosition}>
      <div className="ss-container-flex">
        <div className="ss-grid" style={{ paddingTop: gateway ? '20px' : '' }}>
          {gateway || isMobileSize ? (
            ''
          ) : (
            <div className="ss-header">
              <h4 data-testid="ss-header">{i18n.t('topOfPage.header')}</h4>
              <h6 data-testid="ss-subhead">{i18n.t('topOfPage.subhead')}</h6>
            </div>
          )}
          <div className="ss-main">
            <AuthContext.Provider
              value={{
                stack,
                i18n,
                gateway,
                client: ssClient,
                printers,
                authProvider,
                language,
                country,
                deviceID,
                isMobileSize
              }}
            >
              {loadingPrinters || errorToLoad ? (
                <LoadingPage
                  isLoadingPageDetails={isLoadingPageDetails}
                  onVisibilityChanged={onVisibilityChanged}
                  timeout={TIMEOUT}
                  onTimeout={fetchPrinters}
                  errorToLoad={errorToLoad}
                />
              ) : (
                <div className="ss-printer-list">
                  <PrinterList />
                </div>
              )}
            </AuthContext.Provider>
          </div>
          <div className="ss-aside">
            {loadingPrinters || errorToLoad || (
              <HelperInfo i18n={i18n} mobileSize={isMobileSize} />
            )}
          </div>
        </div>
      </div>
    </ToastProvider>
  )
}

export default SmartSecurity
