import React, { useContext } from 'react'
import {
  IconShieldCheck,
  IconShieldWarning,
  IconShieldOff
} from '@veneer/core/dist/scripts/icons'
import ProgressIndicator from '@veneer/core/dist/scripts/progress_indicator'
import { Sizes, AssessmentResult, BadgeColors } from '../static/consts'
import AssessmentHooksContext from '../contexts/AssessmentHooksContext'
import { PrinterBadge } from './styles'

const BadgeIcon = () => {
  const {
    useSecurityAssessmentStatusHook: {
      assessmentResult,
      isAssessmentInProgress,
      isRemediationInProgress
    }
  } = useContext(AssessmentHooksContext)

  const iconForResult = (assessmentResult_) => {
    const sr = assessmentResult_.toLowerCase()
    switch (sr) {
      case AssessmentResult.securityMonitored:
        return (
          <IconShieldCheck
            size={Sizes.BADGE_SIZE}
            color={BadgeColors.lightGreen}
            filled
            data-testid="ss-icon-shield-check"
          />
        )
      case AssessmentResult.notMonitored:
        return (
          <IconShieldOff
            size={Sizes.BADGE_SIZE}
            disabled
            filled
            data-testid="ss-icon-shield-off"
          />
        )
      case AssessmentResult.needsAttention:
      default:
        return (
          <IconShieldWarning
            size={Sizes.BADGE_SIZE}
            color={BadgeColors.orange}
            filled
            data-testid="ss-icon-shield-warning"
          />
        )
    }
  }

  const badge = (
    assessmentResult_,
    isAssessmentInProgress_,
    isRemediationInProgress_
  ) => {
    if (isAssessmentInProgress_ || isRemediationInProgress_) {
      return <ProgressIndicator data-testid="ss-progress-indicator" />
    }
    return iconForResult(assessmentResult_)
  }

  return (
    <PrinterBadge>
      {badge(assessmentResult, isAssessmentInProgress, isRemediationInProgress)}
    </PrinterBadge>
  )
}

export default BadgeIcon
