import { useEffect, useContext, useReducer } from 'react'
import { useToast } from '@veneer/core'
import AssessmentContext from '../contexts/AssessmentContext'
import { requestSecurityMonitoringReducer } from '../reducers/requestSecurityMonitoringReducer'
import {
  showModal,
  dismissModal,
  setMonitoringInProgress,
  finishMonitoringInProgress
} from '../actions/monitorSecuritySettings'
import { ToastIDs, ToastTypes, AnalyticsClickEvents } from '../static/consts'
import { getToastErrorStrings } from '../strings/Errors/index'
import AuthContext from '../contexts/AuthContext'
import { registerAnalyticsEvents } from '../utils/analytics'

export const useRequests = ({ useAssessmentStringsHook, isEnabled }) => {
  const { assessment, printer, requestAssessment } =
    useContext(AssessmentContext)

  const { client, i18n } = useContext(AuthContext)

  const [state, dispatch] = useReducer(requestSecurityMonitoringReducer, {
    isMonitoringInProgress: false,
    isShowingModal: false
  })

  const showSecurityModal = () => {
    dispatch(showModal())
  }

  const dismissSecurityModal = () => {
    const linkIDAnalytics = isEnabled
      ? AnalyticsClickEvents.DISMISS_DISABLE_MONITORING
      : AnalyticsClickEvents.DISMISS_ENABLE_MONITORING
    registerAnalyticsEvents(linkIDAnalytics)
    dispatch(dismissModal())
  }

  const { monitorSecuritySettingsStrings } = useAssessmentStringsHook

  const { addToast } = useToast()

  const onToggleMonitoring = async () => {
    let err
    try {
      dispatch(setMonitoringInProgress())
      dispatch(dismissModal())
      const linkIDAnalytics = isEnabled
        ? AnalyticsClickEvents.DISABLE_MONITORING
        : AnalyticsClickEvents.ENABLE_MONITORING
      const newState = !isEnabled
      registerAnalyticsEvents(linkIDAnalytics)

      await client.changeDeviceAssessmentStatus(
        printer.deviceId,
        newState ? 'enabled' : 'disabled'
      )
    } catch (error) {
      err = error

      addToast({
        id: ToastIDs.setMonitoringSecurity.NEGATIVE + printer.deviceId,
        type: ToastTypes.NEGATIVE,
        ...getToastErrorStrings(err.data, i18n)
      })
    }
    if (!err && !isEnabled) {
      addToast({
        id: ToastIDs.setMonitoringSecurity.POSITIVE,
        type: ToastTypes.POSITIVE,
        text: monitorSecuritySettingsStrings.toast
      })
    }

    requestAssessment()
  }

  useEffect(() => {
    dispatch(finishMonitoringInProgress())
  }, [assessment])

  useEffect(() => {
    if (state.isShowingModal) {
      document.body.style.overflow = 'hidden'
    } else {
      document.body.style.overflow = 'auto'
    }
  }, [state])

  return { state, onToggleMonitoring, showSecurityModal, dismissSecurityModal }
}
