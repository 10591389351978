import React from 'react'
import { SmartSecurity } from '../src/index'
import { ShellProps } from '../src/types/shell'
import { MfePropsType } from '../src/types/mfeProps'
import projectNames from '../src/configs/projectNames'
import '../src/styles/global.scss'

declare global {
  interface Window {
    Shell: ShellProps
  }
}

export type SmartSecurityProps = {
  country?: string
  language?: string
}

/**
 * @function Root Main function
 * @param props
 * @returns
 */
export default function Root({ ...props }: MfePropsType & SmartSecurityProps) {
  // You can find all Shell´s props here. See "../src/types/shell" for more details.
  const interfaces = window.Shell.v1
  const language = props?.localization
    ? props?.localization?.language
    : props?.language
  const country = props?.localization
    ? props?.localization?.country
    : props?.country
  // You can find the "stack" information by props
  // const stack = props.stack.
  return (
    <section
      className={`${projectNames.namespace}`}
      id={projectNames.packageJsonName}
    >
      {console.log('SS PROPS', props)}
      <SmartSecurity
        {...props}
        language={language}
        country={country}
        stack={props?.stack}
        authProvider={interfaces?.authProvider}
      />
    </section>
  )
}
