import { DeviceConfigurationResult } from '../static/consts'

export const compliancyForResult = (configuration) => {
  if (
    configuration &&
    configuration.assessmentData &&
    configuration.assessmentData.result
  ) {
    const lowercasedResult = configuration.assessmentData.result.toLowerCase()
    return lowercasedResult === DeviceConfigurationResult.compliant
  }
  return false
}
