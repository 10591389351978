import { useState, useEffect, useContext } from 'react'
import { DeviceConfigurationsId } from '../static/consts'
import AssessmentContext from '../contexts/AssessmentContext'

export const useDeviceConfigurationsAssessment = () => {
  let configurations = []
  const { assessment } = useContext(AssessmentContext)

  if (
    assessment &&
    assessment.deviceconfigurationsAssessment &&
    assessment.deviceconfigurationsAssessment.configurations
  ) {
    configurations = assessment.deviceconfigurationsAssessment.configurations
  }

  const getConfigurationById = (deviceConfigurationId) => {
    const selectedConfiguration = configurations.find(
      (conf) => conf.id === deviceConfigurationId
    )

    if (
      selectedConfiguration &&
      selectedConfiguration.assessmentData &&
      selectedConfiguration.assessmentData.result
    ) {
      const { result } = selectedConfiguration.assessmentData
      selectedConfiguration.assessmentData.result = result.toLowerCase()
    }

    const unknownConfiguration = {
      id: deviceConfigurationId,
      value: 'unknown',
      assessmentData: {
        result: 'unknown'
      }
    }
    return selectedConfiguration || unknownConfiguration
  }

  const configs = () => {
    const configAdminPasswordSet = getConfigurationById(
      DeviceConfigurationsId.CONFIG_ADMIN_PASSWORD_SET
    )
    const configWifiNetwork = getConfigurationById(
      DeviceConfigurationsId.CONFIG_WIFI_NETWORK
    )
    const configAutoFWUpdate = getConfigurationById(
      DeviceConfigurationsId.CONFIG_AUTO_FW_UPDATE
    )

    return {
      configAdminPasswordSet,
      configWifiNetwork,
      configAutoFWUpdate
    }
  }

  const [deviceConfigurations, setDeviceConfigurations] = useState(configs())

  useEffect(() => {
    const newConfigurations = configs()

    setDeviceConfigurations(newConfigurations)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [assessment])

  return deviceConfigurations
}
