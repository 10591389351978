import React from 'react'
// import '../../css/SmartSecurityStyles.css';

interface WarningMarkerTypes {
  isSecured: boolean
  customCss?: any
}
const WarningMarker = ({ isSecured, customCss }: WarningMarkerTypes) => {
  return isSecured ? null : (
    <div className="status-bar" style={customCss || {}} />
  )
}

export default WarningMarker
