import React, { useContext } from 'react'
// import '../../css/SmartSecurityStyles.css';
import PrinterInfo from './PrinterInfo'
import AuthContext from '../contexts/AuthContext'

const PrinterList = () => {
  const { printers } = useContext(AuthContext)

  return (
    <ul
      style={{
        width: 'auto',
        overflow: 'visible'
      }}
    >
      {printers &&
        printers.map((printer) => {
          return (
            <li
              key={printer.deviceId}
              style={{ marginBottom: '24px', borderRadius: '16px' }}
            >
              <PrinterInfo key={printer.deviceId} printer={printer} />
            </li>
          )
        })}
    </ul>
  )
}

export default PrinterList
