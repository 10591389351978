/* eslint-disable no-use-before-define */
import { useState, useContext } from 'react'
import { useToast } from '@veneer/core'
import { AssessmentStatus, ToastIDs, ToastTypes } from '../static/consts'

import AuthContext from '../contexts/AuthContext'
import { getToastErrorStrings, getErrorMessage } from '../strings/Errors/index'

export const useAssessment = ({ printer }) => {
  const { i18n, client } = useContext(AuthContext)
  const [isFirstLoading, setIsFirstLoading] = useState(
    printer.showAssementDetails(true)
  )
  const [hasLoadingError, setHasLoadingError] = useState(false)
  let hasRequestInProgress = false
  let assessmentUpdateRequired = false

  const [errorMessage, setErrorMessage] = useState('')
  const [showButton, setShowButton] = useState(false)
  const [assessment, setAssessment] = useState({})
  const RETRY_TIMEOUT = 3000
  const { addToast } = useToast()

  const [, setTimerId] = useState(undefined)

  function assessmentInProgress(assessmentResult) {
    return (
      assessmentResult &&
      assessmentResult.securityAssessmentstatus &&
      assessmentResult.securityAssessmentstatus.assessmentStatus ===
        AssessmentStatus.inProgressAssessment
    )
  }

  async function fetchAssessment() {
    hasRequestInProgress = true
    assessmentUpdateRequired = false
    setHasLoadingError(false)

    try {
      const result = await client.getSecurityAssessment(printer.deviceId)
      setAssessment(result.data)
      if (assessmentUpdateRequired) {
        fetchAssessment()
      } else {
        if (isFirstLoading) {
          setIsFirstLoading(false)
        }
        hasRequestInProgress = false
      }

      if (assessmentInProgress(result.data)) {
        scheduleAssessmentRefresh()
      }
    } catch (error) {
      const data = error.data || {}
      const toastData = {
        id: ToastIDs.setAutoFWUpdate.NEGATIVE,
        type: ToastTypes.NEGATIVE,
        ...getToastErrorStrings(data, i18n)
      }

      addToast(toastData)
      const messageControl = getErrorMessage(data, i18n)
      setShowButton(messageControl.showButton)
      if (isFirstLoading) {
        setHasLoadingError(true)
        setErrorMessage(messageControl.message)
      }
      hasRequestInProgress = false
    }
  }

  function requestAssessment() {
    if (hasRequestInProgress) {
      assessmentUpdateRequired = true
    } else {
      fetchAssessment()
    }
  }

  function scheduleAssessmentRefresh() {
    const to = setTimeout(() => {
      requestAssessment()
    }, RETRY_TIMEOUT)

    setTimerId((t) => {
      if (t) {
        clearTimeout(t)
      }
      return to
    })
  }

  return {
    loading: isFirstLoading,
    assessmentUpdateRequired,
    assessment,
    hasLoadingError,
    errorMessage,
    showButton,
    fetchAssessment,
    requestAssessment
  }
}
