import React from 'react'
import List from '@veneer/core/dist/scripts/list'
import PropTypes from 'prop-types'
import CardItem from '../CardItem'
import CardItemContent from '../CardItemContent'
import { withDropShadow } from '../../hocs/withDropShadow'

const Mobile = ({ i18n, strings, showAssessment }) => {
  const SecurityNotAvailable = i18n.t(
    'monitorSecuritySettings.descriptionTenantUnknown'
  )

  const input = <span>{SecurityNotAvailable}</span>

  return withDropShadow(
    List,
    true
  )({
    children: (
      <>
        <div>
          <CardItem
            key={strings.title}
            contentItem={
              showAssessment ? (
                <CardItemContent
                  title={strings.title}
                  content={strings.description}
                />
              ) : (
                <CardItemContent title={strings.title} content={input} />
              )
            }
          />
        </div>
      </>
    )
  })
}

export default Mobile

Mobile.propTypes = {
  i18n: PropTypes.object.isRequired,
  strings: PropTypes.object.isRequired,
  showAssessment: PropTypes.bool.isRequired
}
