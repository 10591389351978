/* eslint-disable */ // TODO remove when
import React, { useContext } from 'react'
import { defaultPrinterIcon } from '../static/consts'
import AssessmentContext from '../contexts/AssessmentContext'
// import '../../css/SmartSecurityStyles.css';

const PrinterIcon = () => {
  const { printer } = useContext(AssessmentContext)

  function getImages(printerImages) {
    return (
      printerImages &&
      printerImages.map((url) => {
        const [width, height] = getIconSize(url.url ?? url)
        return {
          url: url?.url ?? url,
          width,
          height
        }
      })
    )
  }

  const imagesDict = getImages(printer.images || [])

  function getIconSize(url) {
    var splitted = url.split('/')
    var lastItem = splitted.length - 1
    var splitPng = splitted[lastItem].split('.png')
    var iconSizeString = splitPng[0].split('x')
    var iconSizeArray = iconSizeString.map(Number)

    return iconSizeArray
  }

  function printerIcon() {
    const sortedObj = imagesDict.sort((a, b) => b.width - a.width)
    const iconUrl = sortedObj.map((a) => a.url)

    return iconUrl[0]
  }

  function statusImage() {
    return printerIcon() || defaultPrinterIcon
  }

  return (
    <img
      className="printer-image"
      src={statusImage()}
      data-testid="ss-printer-image"
    />
  )
}

export default PrinterIcon
