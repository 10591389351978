import { useState, useEffect } from 'react'
import DeviceClient from '../client/DeviceClient'
import { acceptedDeviceModelsList, getEnvConfig } from '../static/consts'
// import AuthContext from '../contexts/AuthContext'

const useDevice = ({ stack, authProvider, deviceID }) => {
  const [printers, setPrinters] = useState([])
  const [loadingPrinters, setLoadingPrinters] = useState(true)
  const [isLoadingPageDetails, setIsLoadingPageDetails] = useState(true)
  const [errorToLoad, setErrorToLoad] = useState(false)

  // const { stack, authProvider } = useContext(AuthContext)
  const config = getEnvConfig(stack)

  const devicesClient = new DeviceClient(
    stack,
    config.deviceApiUrl,
    authProvider
  )

  const fetchDevicesAPI = async () => {
    setErrorToLoad(false)
    setLoadingPrinters(true)
    setIsLoadingPageDetails(true)

    try {
      let result = await devicesClient.exportDevicesApi()
      // added filter as the end for flex & e2e, so commenting this part
      result = result.filter((e) => {
        return (
          e?.identity?.platformIdentifier &&
          acceptedDeviceModelsList.includes(e?.identity?.platformIdentifier)
        )
      })

      if (deviceID) {
        const printer = result.find((e) => {
          return e.deviceId === deviceID
        })
        result = printer ? [printer] : []
      }
      const data =
        // await Promise.all(
        result.map((device) => {
          // await devicesClient.getDeviceByID(device.deviceId)
          // const isFlex = false
          // const isE2E = true
          const isBasicPrinter = true

          // switch (device.platformIdentifier.toLowerCase()) {
          //   case PlatformIdentifier.GEN1:
          //   case PlatformIdentifier.GEN2:
          //     isBasicPrinter = true;
          //     break;
          //   case PlatformIdentifier.GEN2YETI:
          //     if (device.bizModel.toLowerCase() === bizModel.FLEX)
          //       isBasicPrinter = true;
          //     break;
          //   default:
          //     isBasicPrinter = false;
          // }

          // if (!isBasicPrinter) {
          //   isFlex = device.bizModel.toLowerCase() === bizModel.FLEX;
          //   isE2E = device.bizModel.toLowerCase() === bizModel.E2E;
          // }
          const showAssementDetails = (isActive) => {
            // if (isBasicPrinter || isFlex) return false;
            return isActive
          }
          return {
            ...device,
            isBasicPrinter,
            // isFlex,
            // isE2E,
            isTenantUser: false, //userTenant- commenting the tenant user logic as we don't show smartsecurity tab
            showAssementDetails
          }
        })
      // )
      setPrinters(data)
    } catch (error) {
      setIsLoadingPageDetails(false)
      setErrorToLoad(true)
    }
    setLoadingPrinters(false)
  }

  useEffect(() => {
    fetchDevicesAPI()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return {
    fetchDevicesAPI,
    printers,
    loadingPrinters,
    isLoadingPageDetails,
    errorToLoad,
    setIsLoadingPageDetails
  }
}

export default useDevice
