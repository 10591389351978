/* eslint-disable */
import React from 'react'
// import '../../css/JarvisFullScreenSpinnerStyles.css';
import ProgressIndicator from '@veneer/core/dist/scripts/progress_indicator'

interface SpinnerProps {
  show: boolean
  delay: number
  onVisibilityChanged: any
  spinnerTextArray: Array<any>
  minimumDuration?: any
}

interface SpinnerState {
  visible: boolean
  visibilityChangeTimestamp: any
  spinnerTextIndex: number
}

class JarvisFullScreenSpinner extends React.Component<
  SpinnerProps,
  SpinnerState
> {
  visibleTimeout: any
  spinnerTextTimeout: any
  constructor(props) {
    super(props)
    this.state = {
      visible: false,
      visibilityChangeTimestamp: Date.now(),
      spinnerTextIndex: 0
    }
    this.visibleTimeout = undefined
    this.spinnerTextTimeout = undefined
  }

  componentDidMount() {
    if (this.props.show) {
      this.setVisibilityDelayed(true, this.props.delay)
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (!this.props.show && nextProps.show) {
      if (!this.state.visible && !nextState.visible) {
        this.setVisibilityDelayed(true, nextProps.delay)
      } else {
        clearTimeout(this.visibleTimeout)
      }
      return false
    } else if (this.props.show && !nextProps.show) {
      if (this.state.visible && nextState.visible) {
        this.setVisibilityDelayed(
          false,
          Math.max(
            nextProps.minimumDuration -
              (Date.now() - this.state.visibilityChangeTimestamp),
            0
          )
        )
      } else {
        clearTimeout(this.visibleTimeout)
      }
      return false
    }
    return true
  }

  render() {
    return (
      this.state.visible && (
        <div className="ss-full-screen-spinner">
          <div className="full-screen-spinner">
            {this.props.spinnerTextArray.map((spinnerText, index) => (
              <div
                key={'full-screen-spinner-table-' + index}
                className="full-screen-spinner-table"
              >
                <div className="full-screen-spinner-cell-text">
                  <h5
                    className={
                      'full-screen-spinner-text' +
                      (this.state.spinnerTextIndex == index
                        ? ' full-screen-spinner-text-fade'
                        : '')
                    }
                  >
                    {spinnerText.text}
                  </h5>
                </div>
              </div>
            ))}
            <div className="full-screen-spinner-table">
              <div className="full-screen-spinner-cell-image">
                <div className="full-screen-spinner-image">
                  <ProgressIndicator data-testid="ss-progress-indicator" />
                </div>
              </div>
            </div>
          </div>
        </div>
      )
    )
  }

  componentWillUnmount() {
    clearTimeout(this.visibleTimeout)
    clearTimeout(this.spinnerTextTimeout)
  }

  setVisibilityDelayed(visible, delay = 0, spinnerTextIndex = 0) {
    clearTimeout(this.visibleTimeout)
    this.visibleTimeout = setTimeout(() => {
      this.setState({
        visible: visible,
        visibilityChangeTimestamp: Date.now(),
        spinnerTextIndex: spinnerTextIndex
      })
      this.setSpinnerTextDelayed(visible, spinnerTextIndex + 1)
      this.props.onVisibilityChanged(visible)
    }, delay)
  }

  setSpinnerTextDelayed(visible, spinnerTextIndex) {
    clearTimeout(this.spinnerTextTimeout)
    if (
      visible &&
      this.props.spinnerTextArray.length > spinnerTextIndex &&
      this.props.spinnerTextArray[spinnerTextIndex - 1].duration
    ) {
      this.spinnerTextTimeout = setTimeout(() => {
        this.setState({
          spinnerTextIndex: spinnerTextIndex
        })
        this.setSpinnerTextDelayed(true, spinnerTextIndex + 1)
      }, this.props.spinnerTextArray[spinnerTextIndex - 1].duration)
    }
  }
}

export default JarvisFullScreenSpinner
