import { sprintf } from 'sprintf-js'
import {
  DeviceConfigurationResult,
  ConstraintsDataTypes,
  ConstraintsDataTypesKeys
} from '../../static/consts'

const _numberOfExpandedItems = (result) => {
  switch (result) {
    case DeviceConfigurationResult.compliant:
    case DeviceConfigurationResult.nonCompliant:
      return ConstraintsDataTypes.length
    case DeviceConfigurationResult.complianceOff:
      return ConstraintsDataTypes.length - 1
    case DeviceConfigurationResult.unknown:
    default:
      return 0
  }
}

const protectionStatusStrings = (i18n, deviceConfiguration) => {
  return {
    titleGood: i18n.t(`configurations.${deviceConfiguration}.titleGood`),
    descriptionGood: i18n.t(
      `configurations.${deviceConfiguration}.descriptionGood`
    ),
    titleBad: i18n.t(`configurations.${deviceConfiguration}.titleBad`),
    descriptionBad: i18n.t(
      `configurations.${deviceConfiguration}.descriptionBad`
    ),
    titleUnknown: i18n.t(`configurations.${deviceConfiguration}.titleUnknown`),
    descriptionUnknown: i18n.t(
      `configurations.${deviceConfiguration}.descriptionUnknown`
    ),
    toast: i18n.t(`configurations.${deviceConfiguration}.toast`),
    errorToastText: i18n.t(
      `configurations.${deviceConfiguration}.errorToastText`
    ),
    errorToastSub: i18n.t(
      `configurations.${deviceConfiguration}.errorToastSub`
    ),
    fwTitleBad: i18n.t(
      `configurations.${deviceConfiguration}.firmware.titleBad`
    ),
    fwDescriptionBad: i18n.t(
      `configurations.${deviceConfiguration}.firmware.descriptionBad`
    )
  }
}

export const stringsForState = (strings, result) => {
  const formattedStrings = { ...strings }
  const formattedResult = result && result.toLowerCase()
  const numberOfItems = _numberOfExpandedItems(formattedResult)

  switch (formattedResult) {
    case DeviceConfigurationResult.compliant:
    case DeviceConfigurationResult.complianceOff:
      formattedStrings.title = strings.titleGood
      formattedStrings.description = sprintf(
        strings.descriptionGood,
        numberOfItems
      )
      break
    case DeviceConfigurationResult.nonCompliant:
      formattedStrings.title = strings.titleBad
      formattedStrings.description = sprintf(
        strings.descriptionBad,
        numberOfItems
      )
      break
    case DeviceConfigurationResult.unknown:
    default:
      formattedStrings.title = strings.titleUnknown
      formattedStrings.description = strings.descriptionUnknown
      break
  }

  return formattedStrings
}

export const getSecurityCheckStringsForProtectionStatus = (
  checkName,
  strings,
  isCompliant
) => {
  let securityCheckStrings = strings.securityChecks[checkName]

  if (checkName === ConstraintsDataTypesKeys.network) {
    if (isCompliant) {
      securityCheckStrings = {
        ...securityCheckStrings,
        title: securityCheckStrings.titleGood,
        description: securityCheckStrings.descriptionGood
      }
    } else {
      securityCheckStrings = {
        ...securityCheckStrings,
        title: securityCheckStrings.titleBad,
        description: securityCheckStrings.descriptionBad
      }
    }
  }

  return securityCheckStrings
}
export const getSecurityCheckStringsForFlexProtectionStatus = (
  checkName,
  strings,
  isCompliant
) => {
  let securityCheckStrings = strings.securityChecks[checkName]

  if (checkName === ConstraintsDataTypesKeys.network) {
    if (isCompliant) {
      securityCheckStrings = {
        ...securityCheckStrings,
        title: securityCheckStrings.titleGood,
        description: securityCheckStrings.descriptionGood
      }
    } else {
      securityCheckStrings = {
        ...securityCheckStrings,
        title: securityCheckStrings.titleBad,
        description: securityCheckStrings.descriptionBad
      }
    }
  }
  if (checkName === ConstraintsDataTypesKeys.firmware) {
    securityCheckStrings = {
      ...securityCheckStrings,
      title: securityCheckStrings.title,
      description: securityCheckStrings.descriptionFlex
    }
  }
  return securityCheckStrings
}

export default protectionStatusStrings
